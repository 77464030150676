.loaderBackground{
    position: fixed;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2; /* Sit on top */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
}

.loader {
    z-index: 3;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #9b7200;
    width: 120px;
    height: 120px;
    transform: translate(50%, 50%);
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }