.popoutContainer{
    position: fixed;
    top: 100px;
    z-index: 2;
    transform: scaleY(0);
    transform-origin: top center;
}

.popoutWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    width: 250px;
    height: 550px;
}

.popoutButton{
    width: 100%;
    height: 100%;
    border: none;
    font-size: 20px;
    background: rgba(0, 0, 0, 0);
    font-size: 30px;
    color: white;
    transition-duration: .5s;
}

.popoutButton:hover{
    background: rgba(255, 255, 255, 0.8);
    color: black;
}

.popoutWrapper-goDown{
    animation: growDown 1s ease-in-out forwards;
}

.popoutWrapper-goUp{
    animation: collapseUp 1s ease-in-out forwards;
}

.goalLink{
    width: 100%;
    height: 100%;
}

.scheduleLink{
    width: 100%;
    height: 100%;
}

.personalLink{
    width: 100%;
    height: 100%;
}

.inviteLink{
    width: 100%;
    height: 100%;
}

@media(max-width: 425px){
    .popoutContainer{
        width: 50%;
    }
    
    .popoutWrapper{
        width: 100%;
    }

    .popoutButton{
        font-size: 20px;
    }
}

@media(max-width: 320px){
    .popoutContainer{
        width: 100%;
    }
    
    .popoutWrapper{
        width: 100%;
    }

    .popoutButton{
        font-size: 30px;
    }
}

@media(max-height: 550px){
    
    .popoutWrapper{
        height: 300px
    }

    .popoutButton{
        font-size: 25px;
    }
}

@media(max-height: 550px){
    
    .popoutWrapper{
        height: 200px
    }

    .popoutButton{
        font-size: 20px;
    }
}

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(1);
    }
}

@keyframes collapseUp {
    0% {
        transform: scaleY(1);
    }
    20% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(0);
    }
}