.goalContainer{
    width: 100%;
    height: 100%;
    background: #6b4e00;
}

.goalWrapper{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}

#goalWrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
    visibility: hidden;
  } 

#goalWrapper::-webkit-scrollbar-thumb{
    background-color: black;
}
  
#goalWrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.goalList{
    width: 100%;
    height: 100%;
    background: #6b4e00;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.goalFiller{
    width: 100%;
    min-height: 100px;
    background: black;
}

.Goal-List-Item{
    width:100%;
    min-height: 100px;
    background: #c08d00;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}

.Goal-List-Item{
    width:100%;
    min-height: 100px;
    background: #bb911f;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}

.Goal-Grid-Cell{
    width: 24vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Goal-List-Item-Title{
    font-size: 30px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition-duration: .5s;
    margin: 0;
}

.Goal-List-Item-Title:hover{
    color: #ffffff;
    cursor: pointer;
}

.Goal-List-Item-Progress{
    font-size: 30px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.Goal-List-Item-Edit-Button{
    background: #ac7e00;
    font-size: 30px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
    width: 50px;
    height: 50px;
}

.Goal-List-Item-Edit-Button:hover{
    background: #ffbb00;
}

.Goal-List-Item-Delete-Button{
    background: #ac7e00;
    font-size: 30px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
    width: 50px;
    height: 50px;
}

.Goal-List-Item-Delete-Button:hover{
    background: #ffbb00;
}

.Goal-Create-Button{
    height: 50px;
    width: 75px;
    border: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 2;
    background:#ffbb00;
    border-radius: 20px;
    font-size: 25px;
    box-shadow: 2px 2px 10px black;
}

@media(max-width: 1024px){

    .Goal-List-Item{
        width:100%;
        min-height: 100px;
        background: #c08d00;
        display: grid;
        grid-template-areas: 1fr 1fr;
        grid-template-areas: 
        'title title edit edit'
        'progress progress delete delete';
        justify-content: center;
        justify-items: center;
        align-content: center;
        align-items: center;
    }

    .Goal-Grid-Cell{
        width: 47vw;
    }

    .Goal-List-Item-Title{
        font-size: 20px;
    }
    
    .Goal-List-Item-Progress{
        font-size: 20px;
    }
    
    .Goal-List-Item-Edit-Button{
        font-size: 20px;
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
        width: 40px;
        height: 40px;
    }
    
    .Goal-List-Item-Delete-Button{
        font-size: 20px;
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
        width: 40px;
        height: 40px;
    }

    .Goal-Create-Button{
        height: 35px;
        width: 60px;
        bottom: 10px;
        right: 10px;
        font-size: 20px;
    }

    .Goal-Grid-Cell-Title{
        grid-area: title;
    }

    .Goal-Grid-Cell-Progress{
        grid-area: progress;
    }

    .Goal-Grid-Cell-Edit{
        grid-area: edit;
        height: 50px;
    }

    .Goal-Grid-Cell-Delete{
        grid-area: delete;
        height: 50px;
    }
    

}

@media(max-width: 630px){
    .Goal-List-Item-Title{
        font-size: 13px;
    }
    
    .Goal-List-Item-Progress{
        font-size: 13px;
    }
    
    .Goal-List-Item-Edit-Button{
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
    }
    
    .Goal-List-Item-Delete-Button{
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
    }

    .Goal-Create-Button{
        height: 35px;
        width: 60px;
        bottom: 10px;
        right: 10px;
        font-size: 20px;
    }

}

@media(max-width: 319px){
    /*.Goal-List-Item{
        grid-template-columns: 1fr 1fr 1fr;
    }*/

  /*  .Goal-Grid-Cell-Progress{
        display: none;
    }*/

}