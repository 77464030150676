.Goal-Detail-Container{
}

.Goal-Detail-Wrapper{
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Goal-Detail-Form-Wrapper{
    width: 70%;
    height: 60%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    background: #503b00;
    box-shadow: 2px 2px 10px black;
}

.Goal-Detail-Title-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: #9b7200;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Goal-Detail-Title-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color:black;
    height: 10px;
    /*border-radius: 10px;*/
  } 

.Goal-Detail-Title-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.Goal-Detail-Title-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #9b7200;
    /*border-radius: 10px;*/
}

.Goal-Detail-Progress-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: #815f00;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Goal-Detail-Progress-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color:black;
    height: 10px;
    /*border-radius: 10px;*/
  } 

.Goal-Detail-Progress-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.Goal-Detail-Progress-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #815f00;
    /*border-radius: 10px;*/
}

.Goal-Detail-Description-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #6e5100;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Goal-Detail-Description-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color:black;
    height: 10px;
    /*border-radius: 10px;*/
  } 

.Goal-Detail-Description-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.Goal-Detail-Description-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #6e5100;
    /*border-radius: 10px;*/
}

.Goal-Detail-Title-Wrapper-Parent{
    overflow-x: auto;
    height: 20%;
}

.Goal-Detail-Progress-Wrapper-Parent{
    overflow-x: auto;
    height: 25%;
}

.Goal-Detail-Description-Wrapper-Parent{
    overflow-x: auto;
    height: 55%;
}

.Goal-Detail-Title{
    font-size: 50px;
    margin-left: 5px;
    white-space: nowrap;
}

.Goal-Detail-Progress{
    color: #fffb00;
    margin-left: 5px;
    white-space: nowrap;
}

.Goal-Detail-Description{
    margin-left: 5px;
    white-space: nowrap;
}


@media(max-width: 770px){
    
    /*.Goal-Detail-Title-Wrapper{
        justify-content: center;
        padding: 5px;
    }
    
    .Goal-Detail-Progress-Wrapper{
        justify-content: center;
        padding: 5px;
    }

    .Goal-Detail-Description-Wrapper{
        align-items: center;
        padding: 5px;
    }
    
    .Goal-Detail-Progress{
        margin-left: 0px;
    }
    
    .Goal-Detail-Description{
        margin-left: 0px;
    }*/

    .Goal-Detail-Title{
        font-size: 30px;
    }

}

/*.Goal-Detail-Title-Label{
    margin-right: 20px;
}

.Goal-Detail-Title-Input{

}

.Goal-Detail-Progress-Label{
    margin-right: 20px;
}

.Goal-Detail-Progress-Input{
    
}

.Goal-Detail-Description-Label{
    margin-right: 20px;
}

.Goal-Detail-Description-Input{
    
}*/