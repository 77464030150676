.Student-Detail-Container-Admin{
}

.Student-Detail-Wrapper-Admin{
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Student-Detail-Form-Wrapper-Admin{
    width: 70%;
    height: 60%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    background: #503b00;
    box-shadow: 2px 2px 10px black;
}

.Student-Detail-FirstName-Wrapper-Admin-Parent{
    height: 16.6%;
    overflow-x: auto;
}

.Student-Detail-LastName-Wrapper-Admin-Parent{
    height: 16.6%;
    overflow-x: auto;
}

.Student-Detail-DisplayName-Wrapper-Admin-Parent{
    height: 16.6%;
    overflow-x: auto;
}

.Student-Detail-Email-Wrapper-Admin-Parent{
    height: 16.6%;
    overflow-x: auto;
}

.Student-Detail-Weight-Wrapper-Admin-Parent{
    height: 16.6%;
    overflow-x: auto;
}

.Student-Detail-Height-Wrapper-Admin-Parent{
    height: 16.6%;
    overflow-x: auto;
}

.Student-Detail-Workout-Wrapper-Admin-Parent{
    height: 16.6%;
    overflow-x: auto;
}

.Student-Detail-FirstName-Wrapper-Admin{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #ffbb00;
    height: 100%;
    white-space: nowrap;
}

.Student-Detail-FirstName-Wrapper-Admin::-webkit-scrollbar {
    width: 15px;
    background-color: #ffbb00;
    height: 10px;
    /*border-radius: 10px;*/
  } 

  .Student-Detail-FirstName-Wrapper-Admin::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
.Student-Detail-FirstName-Wrapper-Admin::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffbb00;
    /*border-radius: 10px;*/
}

.Student-Detail-LastName-Wrapper-Admin{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #cc9600;
    height: 100%;
    white-space: nowrap;
}

.Student-Detail-LastName-Wrapper-Admin::-webkit-scrollbar {
    width: 15px;
    background-color: #ffbb00;
    height: 10px;
    /*border-radius: 10px;*/
  } 

  .Student-Detail-LastName-Wrapper-Admin::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
.Student-Detail-LastName-Wrapper-Admin::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffbb00;
    /*border-radius: 10px;*/
}

.Student-Detail-DisplayName-Wrapper-Admin{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #ffbb00;
    height: 100%;
    white-space: nowrap;
}

.Student-Detail-DisplayName-Wrapper-Admin::-webkit-scrollbar {
    width: 15px;
    background-color: #ffbb00;
    height: 10px;
    /*border-radius: 10px;*/
  } 

  .Student-Detail-DisplayName-Wrapper-Admin::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
.Student-Detail-DisplayName-Wrapper-Admin::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffbb00;
    /*border-radius: 10px;*/
}

.Student-Detail-Email-Wrapper-Admin{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #cc9600;
    height: 100%;
    white-space: nowrap;
}

.Student-Detail-Email-Wrapper-Admin::-webkit-scrollbar {
    width: 15px;
    background-color: #ffbb00;
    height: 10px;
    /*border-radius: 10px;*/
  } 

  .Student-Detail-Email-Wrapper-Admin::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
.Student-Detail-Email-Wrapper-Admin::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffbb00;
    /*border-radius: 10px;*/
}

.Student-Detail-Weight-Wrapper-Admin{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #ffbb00;
    height: 100%;
    white-space: nowrap;
}

.Student-Detail-Weight-Wrapper-Admin::-webkit-scrollbar {
    width: 15px;
    background-color: #ffbb00;
    height: 10px;
    /*border-radius: 10px;*/
  } 

.Student-Detail-Weight-Wrapper-Admin::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
.Student-Detail-Weight-Wrapper-Admin::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffbb00;
    /*border-radius: 10px;*/
}

.Student-Detail-Height-Wrapper-Admin{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #cc9600;
    height: 100%;
    white-space: nowrap;
}

.Student-Detail-Height-Wrapper-Admin::-webkit-scrollbar {
    width: 15px;
    background-color: #ffbb00;
    height: 10px;
    /*border-radius: 10px;*/
  } 

.Student-Detail-Height-Wrapper-Admin::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
.Student-Detail-Height-Wrapper-Admin::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffbb00;
    /*border-radius: 10px;*/
}

.Student-Detail-Workout-Wrapper-Admin{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #ffbb00;
    height: 100%;
    white-space: nowrap;
    transition-duration: 0.5s;
}

.Student-Detail-Workout-Wrapper-Admin::-webkit-scrollbar {
    width: 15px;
    background-color: #ffbb00;
    height: 10px;
    /*border-radius: 10px;*/
  } 

.Student-Detail-Workout-Wrapper-Admin::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
.Student-Detail-Workout-Wrapper-Admin::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffbb00;
    /*border-radius: 10px;*/
}

.Student-Detail-Workout-Wrapper-Admin:hover{
    background: #a37800;
}

.Student-Detail-FirstName-Admin{
    margin-left: 10px;
}

.Student-Detail-LastName-Admin{
    margin-left: 10px;
}

.Student-Detail-DisplayName-Admin{
    margin-left: 10px;
}

.Student-Detail-Email-Admin{
    margin-left: 10px;
}

.Student-Detail-Weight-Admin{
    margin-left: 10px;
}

.Student-Detail-Height-Admin{
    margin-left: 10px;
}

.Student-Detail-Workout-Admin{
    margin-left: 10px;
    cursor: pointer;
    transition-duration: 0.5s;
}

.Student-Detail-Workout-Admin:hover{
    color: white;
}


@media(max-width: 770px){
    
    .Student-Detail-FirstName-Admin{
        font-size: 20px;
    }
    
    .Student-Detail-LastName-Admin{
        font-size: 20px;
    }

    .Student-Detail-DisplayName-Admin{
        font-size: 20px;
    }
    
    .Student-Detail-Email-Admin{
        font-size: 20px;
    }
    
    .Student-Detail-Weight-Admin{
        font-size: 20px;
    }
    
    .Student-Detail-Height-Admin{
        font-size: 20px;
    }
    
    .Student-Detail-Workout-Admin{
        font-size: 20px;
    }

}

@media(max-width: 425px){
    
    .Student-Detail-FirstName-Admin{
        font-size: 15px;
    }
    
    .Student-Detail-LastName-Admin{
        font-size: 15px;
    }

    .Student-Detail-DisplayName-Admin{
        font-size: 15px;
    }
    
    .Student-Detail-Email-Admin{
        font-size: 15px;
    }
    
    .Student-Detail-Weight-Admin{
        font-size: 15px;
    }
    
    .Student-Detail-Height-Admin{
        font-size: 15px;
    }
    
    .Student-Detail-Workout-Admin{
        font-size: 15px;
    }

}

/*.Goal-Detail-Title-Label{
    margin-right: 20px;
}

.Goal-Detail-Title-Input{

}

.Goal-Detail-Progress-Label{
    margin-right: 20px;
}

.Goal-Detail-Progress-Input{
    
}

.Goal-Detail-Description-Label{
    margin-right: 20px;
}

.Goal-Detail-Description-Input{
    
}*/