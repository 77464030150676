.Exercise-Edit-Container-Admin{
}

.Exercise-Edit-Wrapper-Admin{
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Exercise-Edit-Form-Wrapper-Admin{
    width: 50%;
    height: 50%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    background: #503b00;
    box-shadow: 2px 2px 10px black;
}

.Exercise-Edit-Title-Wrapper-Admin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40%;
}

.Exercise-Edit-Title-Label-Admin{
    font-size: 40px;
    text-align: center;
}

.Exercise-Edit-Title-Input-Admin{
    height: 30px;
    width: 350px;
    border: none;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
}

.Exercise-Edit-Button-Area-Admin{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 20%;
}

.Exercise-Edit-Save-Button-Admin{
    width: 100px;
    height: 30px;
    background: #ac8e3b;
    font-size: 20px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
    margin-right: 20px;
}

.Exercise-Edit-Save-Button-Admin:hover{
    background:#f5ca55;
}

.Exercise-Edit-Cancel-Button-Admin{
    width: 100px;
    height: 30px;
    background:#524626;
    font-size: 20px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
}

.Exercise-Edit-Cancel-Button-Admin:hover{
    background: #f5ca55;
}

.Exercise-Edit-Image-Area{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Exercise-Edit-ViewImage-Area{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Exercise-Edit-Image-Input{
    background-color: black;
    border-color: white;
    height: 30px;
    border-width: 0 0 1px;
    color: white;
    font-size: 20px;
    padding: 5px;
    text-align: center;
    outline-width: 0;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    display: none;
}

.Exercise-Edit-Image-Label{
    padding: 5px;
    font-size: 20px;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    text-align: center;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
}

.Exercise-Edit-Image-Label:hover{
    background: #f1c752;
}

.Exercise-Edit-ViewImage-Button-Admin{
    width: 50px;
    height: 30px;
    background: #ac8e3b;
    font-size: 20px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
}

.Exercise-Edit-ViewImage-Button-Admin:hover{
    background: #f1c752;
}

.Exercise-Edit-ImageView-CoverContainer-Admin{
    /*display: none;*/
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Sit on top */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
}

.Exercise-Edit-ImageView-Container-Admin{
    position: absolute;
    height: 300px;
    width: 400px;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 5;
    border-radius: 10px;
    box-shadow: 2px 2px 10px black;
}

.Exercise-Edit-ImageView-Image-Admin{
    height: 80%;
    width: 80%;
    object-fit: fill;
    border-radius: 10px;
    margin-bottom: 10px;
}

.Exercise-Edit-Ok-Button-Admin{
    width: 100px;
    height: 30px;
    background: #ac8e3b;
    font-size: 20px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
}

.Exercise-Edit-Ok-Button-Admin:hover{
    background: #f1c752;
}

@media(max-width: 860px){
    
    .Exercise-Edit-Form-Wrapper-Admin{
        width: 80%;
    }

    .Exercise-Edit-Title-Wrapper-Admin{
        justify-content: center;
        padding: 5px;
    }

    .Exercise-Edit-Title-Admin{
        margin-left: 0px;
    }

    .Exercise-Edit-Title-Label-Admin{
        font-size: 40px;
    }
     
    .Exercise-Edit-Title-Input-Admin{
        width: 80%;
    }
}

@media(max-width: 530px){

    .Exercise-Edit-Form-Wrapper-Admin{
        width: 90%;
    }

    .Exercise-Edit-Title-Label-Admin{
        font-size: 35px;
    }
    
    .Exercise-Edit-ImageView-Container-Admin{
        position: absolute;
        height: 130px;
        width: 230px;
        background: black;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        z-index: 5;
    }

    .Exercise-Edit-ImageView-Image-Admin{
        height: 80%;
        width: 80%;
        object-fit: fill;
        margin-bottom: 10px;
    }

    .Exercise-Edit-Ok-Button-Admin{
        width: 80px;
        height: 30px;
        background: #ac8e3b;
        font-size: 15px;
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
    }

}

@media(max-height: 750px){
    /*.Exercise-Edit-Title-Label-Admin{
        font-size: 30px;
    }*/

}
