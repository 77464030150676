body::-webkit-scrollbar {
    display:none;
    width: 15px;
    background-color: #F5F5F5;
    visibility: hidden;
    border-radius: 10px;
  } 

  body::-webkit-scrollbar-thumb{
    display:none;
    background-color: rgb(46, 46, 46);
    border-radius: 10px;
}
  
body::-webkit-scrollbar-track {
    display:none;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scheduleContainer{
    
}

.scheduleWrapper{
    position: relative;
    top: 130px;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1750px;
    /*width: 100%;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
    overflow-y: visible;
}

.Schedule-Week-ScheduleGrade-Container{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: horizontal;
    justify-content: center;
}

.Schedule-Week-ScheduleGrade-Wrapper{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: horizontal;
    justify-content: center;
}

.scheduleGrid{ /* Bring day row with the screen during scroll?? */
    position: absolute;
    width: 98%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 2px 2px 10px black;
}

.Schedule-Grid-Title{
    width: 100%;
    height: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #7c5b00;
    border: 1px black;
    border-style: solid;
}

.Schedule-Grid-Title-Text{
    text-align: center;
    margin: 0;
}

.Schedule-Grid-Title-Student{ 
    text-align: center;
    margin: 0;
}

.Schedule-Grid-Categories{
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: #997000;
    border: 1px black;
    border-style: solid;
}

.Schedule-Grid-Categories-Days{
    width: 15%;
    height: 100%;
    border: 1px black;
    border-style: solid;
}

.Schedule-Grid-Categories-Type{
    width: 16%;
    height: 100%;
    border: 1px black;
    border-style: solid;
}

.Schedule-Grid-Categories-Intensity{
    width: 30%;
    height: 100%;
    border: 1px black;
    border-style: solid;
}

.Schedule-Grid-Categories-Time{
    width: 24%;
    height: 100%;
    border: 1px black;
    border-style: solid;
}

.Schedule-Grid-Categories-Frequency{
    width: 15%;
    height: 100%;
    border: 1px black;
    border-style: solid;
}

.Schedule-Grid-Chart{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.Schedule-Grid-Chart-Titles{
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #d4d4d4;
    border: 1px black;
    border-style: solid;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 100px;
}

.Schedule-Grid-Chart-Monday{
    height: 19%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px black;
    border-style: solid;
}

.Schedule-Grid-Chart-Tuesday{
    height: 19%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px black;
    border-style: solid;
}

.Schedule-Grid-Chart-Wednesday{
    height: 19%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px black;
    border-style: solid;
}

.Schedule-Grid-Chart-Thursday{
    height: 19%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px black;
    border-style: solid;
}

.Schedule-Grid-Chart-Friday{
    height: 19%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px black;
    border-style: solid;
}

.Schedule-Grid-Categories-Title{
    margin: 0;
    text-align: center;
    font-size: 20px;
}

.Schedule-Grid-Categories-Text{
    margin: 0;
    text-align: center;
    font-size: 12px;
}

.Schedule-Week-Selection-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.Schedule-Week-Selection{
    width: 200px;
    box-shadow: 2px 2px 10px black;
}

.workoutInput{
    width: 190px;
    height: 40px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(#ffffff, #aaaaaa);
    font-size: 20px;
    padding: 3px;
}

.Workout-Input-Label{
    margin: 0;
    margin-right: 10px;
}

.Schedule-Week-Filter-Wrapper{
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Schedule-Week-Classroom-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Schedule-Week-Print-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.Schedule-Week-Print{
    height: 50px;
    width: 120px;
    background: #ac7e00;
    border: none;
    box-shadow: 2px 2px 10px black;
    transition-duration: .75s;
}

.Schedule-Week-Print:hover{
    background: #ffbb00;
}

.Schedule-Print-Icon{
    size: 40;
}

.Workout-Select-Classroom-Label{
    margin: 0;
    margin-right: 10px;
}

.Workout-Classroom-Select{
    width: auto;
    height: 40px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(#ffffff, #aaaaaa);
    font-size: 20px;
    padding: 3px;
}

@media(max-width: 800px){
    .Schedule-Week-Filter-Wrapper{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .Schedule-Week-Selection-Wrapper{
        margin-bottom: 10px;
    }

}

@media(max-width: 700px){
    .Schedule-Grid-Categories-Title{
        font-size: 15px;
    }

    .Schedule-Grid-Categories-Text{
        margin: 0;
        text-align: center;
        font-size: 10px;
    }
}

@media(max-width: 600px){
    .Schedule-Grid-Categories-Days{
        width: 15%;
    }
    
    .Schedule-Grid-Categories-Type{
        width: 16%;
    }
    
    .Schedule-Grid-Categories-Intensity{
        width: 42.6%;
    }
    
    .Schedule-Grid-Categories-Time{
        width: 25.4%;
    }
    
    .Schedule-Grid-Categories-Frequency{
        display: none;
    }
}

@media(max-width: 500px){
    .Schedule-Grid-Categories-Title{
        font-size: 12px;
    }

    .Schedule-Grid-Categories-Text{
        display: none;
    }

    .Schedule-Grid-Categories-Days{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .Schedule-Grid-Categories-Type{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .Schedule-Grid-Categories-Intensity{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .Schedule-Grid-Categories-Time{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .Schedule-Grid-Categories-Frequency{
        display: none
    }
}

@media(max-width: 425px){
    .Schedule-Grid-Categories-Time{
        display: none;
    }

    .Schedule-Grid-Categories-Type{
        width: 21%;
    }
    
    .Schedule-Grid-Categories-Intensity{
        width: 67.6%;
    }
}

@media(max-width: 410px){
    .Schedule-Grid-Title-Text{
        font-size: 20px;
    }

    .Schedule-Grid-Title-Student{
        font-size: 18px;
    }
}

@media(max-width: 450px){
    .Schedule-Week-Selection-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .Schedule-Week-Classroom-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Workout-Select-Classroom-Label{
        margin: 0;
        margin-right: 0;
    }

    .Workout-Input-Label{
        margin-right: 0;
    }

    .Schedule-Week-Filter-Wrapper{
        height: 15%;
    }

    .scheduleGrid{
        height: 85%;
    }

    .scheduleWrapper{
        height: 1250px;
    }
}

@media print {

    .Schedule-Week-Print-Wrapper, .headerContainer, .headerContainer-Admin{
        display: none;
    }

    .scheduleWrapper{
        top: 0px;
        height: auto;
    }

    .scheduleGrid{
        height: auto;
    }

    .Schedule-Grid-Chart-Monday{
        height: auto;
    }
    
    .Schedule-Grid-Chart-Tuesday{
        height: auto;
    }
    
    .Schedule-Grid-Chart-Wednesday{
        height: auto;
    }
    
    .Schedule-Grid-Chart-Thursday{
        height: auto;
    }
    
    .Schedule-Grid-Chart-Friday{
        height: auto;
    }
    
    .Schedule-Grid-Chart-Titles{
        position: static;
    }

    .Schedule-Grid-Title{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #7c5b00;
        border: 1px black;
        border-style: solid;
    }
}

@media print {

    html, body, .Schedule-Week-ScheduleGrade-Container, .scheduleWrapper {
      height:100% !important; 
      margin: 0 !important; 
      padding: 0 !important;
      overflow: visible !important;
    }

    .Schedule-Grid-Chart-Monday, .Schedule-Grid-Chart-Tuesday, .Schedule-Grid-Chart-Wednesday, .Schedule-Grid-Chart-Thursday, .Schedule-Grid-Chart-Friday{
        border-style: solid 1px black;
        height: 100%;
    }

    /*.ScheduleWeek-Row-Wrapper{
        border: solid 1px black;
    }

    .ScheduleWeek-Row-Content-Wrapper{
        border: solid 1px black;
        height: 100%;
    }*/
  
  }