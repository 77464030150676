.Goal-Create-Container{
}

.Goal-Create-Wrapper{
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Goal-Create-Form-Wrapper{
    width: 70%;
    height: 70%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background: #503b00;
    box-shadow: 2px 2px 10px black;
}

.Goal-Create-Title-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #9b7200;
    height: 15%;
}

.Goal-Create-Progress-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #815f00;
    height: 30%;
}

.Goal-Create-Description-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #6e5100;
    height: 45%;
}

.Goal-Create-Title-Label{
    font-size: 30px;
}

.Goal-Create-Progress-Label{
    font-size: 30px;
}

.Goal-Create-Description-Label{
    font-size: 30px;
    margin-bottom: 10px;
}

.Goal-Create-Title-Input{
    height: 30px;
    width: 300px;
    border: none;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
}

.Goal-Create-Progress-Input{
    height: 30px;
    width: 300px;
}

.Goal-Create-Description-TextArea{
    height: 70%;
    width: 80%;
    border: none;
    box-shadow: 2px 2px 10px black;
    padding: 10px;
    font-size: 20px;
    background: #ac8e3b;
}

.Goal-Create-Radio-Wrapper{
    display: flex;
    flex-direction: column;
}

.Goal-Create-Not-Started-Radio-Wrapper{
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.Goal-Create-In-Progress-Radio-Wrapper{
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.Goal-Create-Complete-Radio-Wrapper{
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.Goal-Create-Not-Started-Radio{
   height: 20px;
   width: 20px;
}

.Goal-Create-In-Progress-Radio{
   height: 20px;
   width: 20px;
}

.Goal-Create-Complete-Radio{
   height: 20px;
   width: 20px;
}

.Goal-Create-Not-Started-Label{
    color: #ff0000;
    font-size: 20px;
}

.Goal-Create-In-Progress-Label{
    color: #fbff00;
    font-size: 20px;
}

.Goal-Create-Complete-Label{
    color: #2bff00;
    font-size: 20px;
}

.Goal-Create-Submit-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 10%;
    background: #574000;
}

.Goal-Create-Submit-Button{
    width: 125px;
    height: 40px;
    border: none;
    box-shadow: 2px 2px 10px black;
    background: #ad7f00;
    transition-duration: .5s;
}

.Goal-Create-Submit-Button:hover{
    background: #ffbb00;
}

@media(max-width: 770px){
    
    .Goal-Create-Title-Wrapper{
        justify-content: center;
        padding: 5px;
    }
    
    .Goal-Create-Progress-Wrapper{
        justify-content: center;
        padding: 5px;
    }

    .Goal-Create-Description-Wrapper{
        align-items: center;
        padding: 5px;
    }

    .Goal-Create-Title{
        margin-left: 0px;
    }
    
    .Goal-Create-Progress{
        margin-left: 0px;
    }
    
    .Goal-Create-Description{
        margin-left: 0px;
    }

    .Goal-Create-Title-Input{
        width: 80%;
    }
}

@media(max-width: 425px){

    .Goal-Create-Title-Label{
        font-size: 20px;
    }
    
    .Goal-Create-Progress-Label{
        font-size: 20px;
    }
    
    .Goal-Create-Description-Label{
        font-size: 20px;
    }

    .Goal-Create-Not-Started-Label{
        color: #ff0000;
        font-size: 20px;
    }
    
    .Goal-Create-In-Progress-Label{
        color: #fbff00;
        font-size: 20px;
    }
    
    .Goal-Create-Complete-Label{
        color: #2bff00;
        font-size: 20px;
    }

}

@media(max-height: 750px){

    /*.Goal-Create-Wrapper{
        position: absolute;
        height: 700px;
    }

    .Goal-Create-Title-Label{
        font-size: 15px;
    }
    
    .Goal-Create-Progress-Label{
        font-size: 15px;
    }
    
    .Goal-Create-Description-Label{
        font-size: 15px;
    }

    .Goal-Create-Not-Started-Radio{
        height: 15px;
        width: 15px;
     }
     
     .Goal-Create-In-Progress-Radio{
        height: 15px;
        width: 15px;
     }
     
     .Goal-Create-Complete-Radio{
        height: 15px;
        width: 15px;
     }

    .Goal-Create-Not-Started-Label{
        color: #ff0000;
        font-size: 18px;
    }
    
    .Goal-Create-In-Progress-Label{
        color: #fbff00;
        font-size: 18px;
    }
    
    .Goal-Create-Complete-Label{
        color: #2bff00;
        font-size: 18px;
    }

    .Goal-Create-Form-Wrapper{
        width: 70%;
        height: 80%;
    }*/
}

@media(max-height: 550px){
    /*.Goal-Create-Title-Label{
        font-size: 15px;
        margin-bottom: 0px;
    }
    
    .Goal-Create-Progress-Label{
        font-size: 15px;
        margin-bottom: 0px;
    }
    
    .Goal-Create-Description-Label{
        font-size: 15px;
        margin-bottom: 0px;
    }

    .Goal-Create-Not-Started-Radio{
        height: 15px;
        width: 15px;
     }
     
     .Goal-Create-In-Progress-Radio{
        height: 15px;
        width: 15px;
     }
     
     .Goal-Create-Complete-Radio{
        height: 15px;
        width: 15px;
     }

    .Goal-Create-Not-Started-Label{
        color: #ff0000;
        font-size: 15px;
    }
    
    .Goal-Create-In-Progress-Label{
        color: #fbff00;
        font-size: 15px;
    }
    
    .Goal-Create-Complete-Label{
        color: #2bff00;
        font-size: 15px;
    }

    .Goal-Create-Form-Wrapper{
        width: 70%;
        height: 90%;
    }

    .Goal-Create-Radio-Wrapper{
        display: flex;
        flex-direction: row;
    }

    .Goal-Create-Progress-Wrapper{
        height: 15%;
    }

    .Goal-Create-Title-Wrapper{
        height: 20%;
    }

    .Goal-Create-Description-Wrapper{
        height: 50%;
    }

    .Goal-Create-Submit-Wrapper{
        height: 15%;
    }*/
}
