.classroomContainer{
    display: grid;
    grid-template-areas: 
    'header'
    'buttons';
}

/*.homeWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
*/

.classroomWrapper{
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    grid-area: buttons;
    overflow: scroll;
    overflow-x: hidden;
}

#classroomWrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
    border-radius: 10px;
  } 

#classroomWrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
#classroomWrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.Classroom-Button-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Classroom-Header-Wrapper{
    grid-area: header;
    display: flex;
    flex-direction: column;
    height: 50px;
    width: 100%;
    justify-content: flex-start;
    margin-top: 40px;
}

.classroomButton{
    /*width: 70%;*/
    width: 100%;
    height: 200px;
    font-size: 40px;
    /*margin: 40px;*/
    /*border-radius: 20px;*/
    /*background: radial-gradient(#6b4e00, #4d3801);*/
    background: radial-gradient(#6b4e00, #332500 1200px);
    border: none;
    border-bottom: 5px solid #241a00;
    border-right: 5px solid #241a00;
    box-shadow: 2px 2px 10px black;
    transition-duration: 1s;
}

.classroomButton:hover{
    background: #cc9200;
}


/*.Home-BTN-MuscularStrength:hover{
    background: #8a6300;
}

.Home-BTN-MuscularEndurance:hover{
    background: #8a6300;
}

.Home-BTN-Flexibility:hover{
    background: #8a6300;
}*/

.React-Link-Home{
    width: 100%;
}

.classroomFiller{
    height: 100px;
    width: 100%;
    background: #6b4e00;
}

@media(max-width: 425px){
    .classroomButton{
        height: 150px;
        font-size: 30px;
    }
}