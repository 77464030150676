.exerciseContainer-Admin{
    width: 100%;
    height: 100%;
    background: #6b4e00;
    overflow-y: hidden;
}

.exerciseWrapper-Admin{
    position: absolute;
    top: 100px;
    bottom: 120px;
    left: 0;
    right: 0;
    width: 100%;
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: hidden;
}

/*#exerciseWrapper-Admin::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
    visibility: hidden;
  } 

#exerciseWrapper-Admin::-webkit-scrollbar-thumb{
    background-color: black;
}
  
#exerciseWrapper-Admin::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}*/

.exerciseList-Admin{
    width: 100%;
    height: 100%;
    background: #6b4e00;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}

.exerciseList-Admin::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
    visibility: hidden;
  } 

  .exerciseList-Admin::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.exerciseList-Admin::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.exerciseFiller-Admin{
    width: 100%;
    min-height: 100px;
    background: black;
}

.Exercise-List-Item-Admin{
    width:100%;
    min-height: 100px;
    background: #c08d00;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}

.Exercise-List-Item-Admin{
    width:100%;
    min-height: 100px;
    background: #bb911f;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}

.Exercise-Grid-Cell-Admin{
    width: 24vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Exercise-List-Item-Title-Admin{
    font-size: 30px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    transition-duration: .5s;
}

/*.Exercise-List-Item-Title-Admin:hover{
    color: #ffffff;
    cursor: pointer;
}*/

.Exercise-List-Item-Student-Admin{
    font-size: 30px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Exercise-List-Item-Student-Button-Admin{
    background: #ffffff;
    font-size: 30px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
    width: 125px;
}

.Exercise-List-Item-Student-Button-Admin:hover{
    background: #b1b0b0;
}

.Exercise-List-Item-Edit-Button-Admin{
    background: #ac7e00;
    font-size: 30px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
    width: 50px;
    height: 50px;
}

.Exercise-List-Item-Edit-Button-Admin:hover{
    background: #ffbb00;
}

.Exercise-List-Item-Delete-Button-Admin{
    background: #ac7e00;
    font-size: 30px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
    width: 50px;
    height: 50px;
}


.Exercise-List-Item-Delete-Button-Admin:hover{
    background: #ffbb00;
}

.Exercise-List-Item-Activate-Button-Admin{
    background: #ac7e00;
    font-size: 30px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
    width: 50px;
    height: 50px;
}

.Exercise-List-Item-Activate-Button-Admin:hover{
    background: #ffbb00;
}

.Exercise-Create-Button-Admin{
    height: 50px;
    width: 75px;
    border: none;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 2;
    background:#ffbb00;
    border-radius: 20px;
    font-size: 25px;
    box-shadow: 2px 2px 10px black;
}

.Exercise-Category-Manager-Admin{
    position: absolute;
    bottom: 10px;
    left: 40px;
    border: none;
    background: #ac8e3b;
    z-index: 5;
    transition-duration: 0.5s;
    cursor: pointer;
    height: 40px;
    width: 60px;
    font-size: 20px;
    border-radius: 10px;
}

.Exercise-Category-Manager-Admin:hover{
    background: #dfb84c;
}

.Exercise-Schedule-Manager-Admin{
    position: absolute;
    bottom: 70px;
    left: 40px;
    border: none;
    background: #ac8e3b;
    z-index: 5;
    transition-duration: 0.5s;
    cursor: pointer;
    height: 40px;
    width: 60px;
    font-size: 20px;
    border-radius: 10px;
}

.Exercise-Schedule-Manager-Admin:hover{
    background: #dfb84c;
}

.Fit4Life-SearchbarWrapper-Admin{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Fit4Life-Searchbar-Admin{
    width: 200px;
    height: 30px;
    margin-bottom: 15px;
}

.Fit4Life-SearchButton-Admin{
    width: 200px;
    height: 30px;
    border: none;
    border-radius: 15px;
    background: #ac8e3b;
    transition-duration: 0.5s;
}

.Fit4Life-SearchButton-Admin:hover{
    background: #dfb84c;
}

@media(max-width: 1024px){

    .Exercise-List-Item-Admin{
        width:100%;
        min-height: 100px;
        background: #c08d00;
        display: grid;
        grid-template-areas: 1fr 1fr 1fr;
        grid-template-areas: 
        'title edit activate'
        'title delete activate';
        justify-content: center;
        justify-items: center;
        align-content: center;
        align-items: center;
    }

    .Exercise-Grid-Cell-Admin{
        width: 30vw;
    }

    .Exercise-List-Item-Title-Admin{
        font-size: 20px;
    }

    .Exercise-List-Item-Student-Button-Admin{
        grid-area: student;
        font-size: 20px;
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
    }
    
    .Exercise-List-Item-Edit-Button-Admin{
        font-size: 20px;
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
        width: 40px;
        height: 40px;
    }
    
    .Exercise-List-Item-Delete-Button-Admin{
        font-size: 20px;
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
        width: 40px;
        height: 40px;
    }

    .Exercise-List-Item-Activate-Button-Admin{
        font-size: 20px;
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
        width: 40px;
        height: 40px;
    }

    .Exercise-Create-Button-Admin{
        height: 35px;
        width: 60px;
       /* bottom: 10px;*/
        right: 10px;
        font-size: 20px;
    }

    .Exercise-Grid-Cell-Title-Admin{
        grid-area: title;
        height: 50px;
    }

    .Exercise-Grid-Cell-Student-Admin{
        grid-area: student;
        height: 50px;
    }

    .Exercise-Grid-Cell-Edit-Admin{
        grid-area: edit;
        height: 50px;
    }

    .Exercise-Grid-Cell-Delete-Admin{
        grid-area: delete;
        height: 50px;
    }

    .Exercise-Grid-Cell-Activate-Admin{
        grid-area: activate;
        height: 50px;
    }
    
}

@media(max-width: 630px){
    .Exercise-List-Item-Title-Admin{
        font-size: 13px;
    }

    .Exercise-List-Item-Student-Button-Admin{
        background: #ffffff;
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
    }
    
    .Exercise-List-Item-Edit-Button-Admin{
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
    }
    
    .Exercise-List-Item-Delete-Button-Admin{
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
    }

    .Exercise-List-Item-Activate-Button-Admin{
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
    }

    .Exercise-Create-Button-Admin{
        height: 35px;
        width: 60px;
        /*bottom: 10px;*/
        right: 10px;
        font-size: 20px;
    }

    .Exercise-Category-Manager-Admin{
        font-size: 15px;
        left: 10px;
    }

    .Exercise-Schedule-Manager-Admin{
        font-size: 15px;
        left: 10px;
    }

}

@media(max-width: 420px){
    .Fit4Life-Searchbar-Admin{
        width: 150px;
    }
    
    .Fit4Life-SearchButton-Admin{
        width: 150px;
    }

    /*.Exercise-Category-Manager-Admin{
        font-size: 12px;
        left: 5px;
    }*/
}

@media(max-width: 370px){
    .Fit4Life-Searchbar-Admin{
        width: 120px;
    }
    
    .Fit4Life-SearchButton-Admin{
        width: 120px;
    }

}