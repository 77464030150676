body{
     /* need to monitor and make sure this doesnt interfere with styling on other pages*/
}

.Category-Admin-Container{
    height: 100%;
    width: 100%;
}

.Category-Admin-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 100px;
}

.Category-Admin-Classroom-Wrapper{
    width: 80%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Category-Admin-Exercise-Popout-Cover{
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
}

.Category-Admin-Exercise-Popout-Wrapper{
    width: 300px;
    height: 70%;
    top: 20%;
    left: -310px;
    background: black;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition-duration: 0.5s;
    box-shadow: 2px 2px 10px black;
    z-index: 5;
}

.Category-Admin-Exercise-Search-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.Category-Admin-Exercise-Search{
    width: 80%;
    height: 30px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(#ffffff, #aaaaaa);
    font-size: 20px;
    padding: 3px;
}

.Category-Admin-Exercise-Popout-Header{
    width: 100%;
    height: 100px;
    background: black;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.Category-Admin-Exercise-Popout-Exit{
    font-size: 35px;
    color:rgb(151, 151, 151);
    transition-duration: 0.5s;
    margin-right: 30px;
    cursor: pointer;
}

.Category-Admin-Exercise-Popout-Exit:hover{
    color:rgb(238, 238, 238);
}

.Category-Admin-Exercise-Popout-List{
    width: 100%;
    height: 100%;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}

.Category-Admin-Exercise-Popout-List::-webkit-scrollbar {
    display: none;
    width: 15px;
    height: 10px;
    /*border-radius: 10px;*/
  } 

  .Category-Admin-Exercise-Popout-List::-webkit-scrollbar-thumb{
    display: none;
    background-color: black;
    border-radius: 10px;
}
  
.Category-Admin-Exercise-Popout-List::-webkit-scrollbar-track {
    display: none;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    /*border-radius: 10px;*/
}

.Category-Admin-Exercise-Row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: black;
    width: 100%;
    cursor: pointer;
    transition-duration: 0.5s;
}

.Category-Admin-Exercise-Row:hover{
    background: rgb(255, 255, 255);
}

.Category-Admin-Exercise-Item{
    color:rgb(141, 108, 0);
    cursor: pointer;
}

.Category-Admin-Classroom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
    background: rgb(0, 0, 0);
    border-radius: 20px;
    box-shadow: 2px 2px 10px black;
    height: 200px;
    width: 100%;
}

.Category-Admin-Classroom-Title{
    color: white;
}

.Category-Admin-Button-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.Category-Admin-Add-Button{
    height: 30px;
    width: 30px;
    border-radius: 30px;
    margin-right: 10px;
    border: none;
    box-shadow: 2px 2px 10px black;
    background: green;
    transition-duration: 0.5s;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Category-Admin-Add-Button:hover{
    background: rgb(0, 182, 0);
}

.Category-Admin-Subtract-Button{
    height: 30px;
    width: 30px;
    border-radius: 30px;
    border: none;
    box-shadow: 2px 2px 10px black;
    background: red;
    transition-duration: 0.5s;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Category-Admin-Subtract-Button:hover{
    background: rgb(255, 56, 56);
}

.Category-Admin-Exercise-Button{
    height: 30px;
    width: 30px;
    border-radius: 30px;
    margin-right: 10px;
    border: none;
    box-shadow: 2px 2px 10px black;
    background: rgb(141, 108, 0);
    transition-duration: 0.5s;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Category-Admin-Exercise-Button:hover{
    background: rgb(180, 138, 0);
}

.Category-Admin-List-Wrapper{
    width: 80%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.Category-Admin-List{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    padding: 10px;
    background: rgb(0, 0, 0);
    border-radius: 20px;
    box-shadow: 2px 2px 10px black;
    height: 150px;
    width: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Category-Admin-List::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
    border-radius: 20px;
  } 

  .Category-Admin-List::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 20px;
}
  
.Category-Admin-List::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 20px;
}

.Category-Admin-ListItem-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 200px;
    background: #7c5b00;
    margin-right: 10px;
    margin-left: 10px;
    transition-duration: 0.5s;
    cursor: pointer;
}

.Category-Admin-ListItemText-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-width: 200px;
    background: #7c5b00;
    margin-right: 10px;
    margin-left: 10px;
    transition-duration: 0.5s;
    cursor: pointer;
    border-radius: 50%;
}

.Category-Admin-ListItem-Wrapper:hover{
    background: #aa7d00;
}

.Category-Admin-ListItem{
    cursor: pointer;
    width: 160px;
    height: 50px;
    font-size: 20px;
    text-align: center;
    background: #7c5b00;
    border: none;
    style: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    outline: none;
}

.Category-Admin-ListItemText{
    cursor: pointer;
    font-size: 20px;
    margin: 0;
    text-align: center;
    background: #7c5b00;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    outline: none;
}

.Category-Admin-ListItem-E{
    cursor: pointer;
    width: 160px;
    height: 50px;
    font-size: 20px;
    text-align: center;
    background: #7c5b00;
    border: none;
    style: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    outline: none;
    color:rgb(255, 255, 255);
}

.Category-Admin-Arrow{
    margin-top: 50px;
    margin-bottom: 50px;
}