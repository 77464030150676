.Personal-Info-Container{
}

.Personal-Info-Wrapper{
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 850px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Personal-Info-Form-Wrapper{
    width: 70%;
    height: 800px;
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    background: #503b00;
    box-shadow: 2px 2px 10px black;
}

.Personal-Info-Field-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-start;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Personal-Info-Field-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: black;
    height: 10px;
    /*border-radius: 10px;*/
  } 

.Personal-Info-Field-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.Personal-Info-Field-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #503b00;
    /*border-radius: 10px;*/
}

.Personal-Info-First-Name-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-start;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Personal-Info-First-Name-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: black;
    height: 10px;
    /*border-radius: 10px;*/
  } 

.Personal-Info-First-Name-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.Personal-Info-First-Name-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #503b00;
    /*border-radius: 10px;*/
}

.Personal-Info-Last-Name-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-start;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Personal-Info-Last-Name-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: black;
    height: 10px;
    /*border-radius: 10px;*/
  } 

.Personal-Info-Last-Name-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.Personal-Info-Last-Name-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#503b00;
    /*border-radius: 10px;*/
}

.Personal-Info-DisplayName-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-start;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Personal-Info-DisplayName-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: black;
    height: 10px;
    /*border-radius: 10px;*/
  } 

.Personal-Info-DisplayName-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.Personal-Info-DisplayName-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#503b00;
    /*border-radius: 10px;*/
}

.Personal-Info-Email-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-start;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Personal-Info-Email-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: black;
    height: 10px;
    /*border-radius: 10px;*/
  } 

  .Personal-Info-Email-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.Personal-Info-Email-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#503b00;
    /*border-radius: 10px;*/
}

.Personal-Info-Weight-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-start;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Personal-Info-Weight-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: black;
    height: 10px;
    /*border-radius: 10px;*/
  } 

.Personal-Info-Weight-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.Personal-Info-Weight-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#503b00;
    /*border-radius: 10px;*/
}

.Personal-Info-Height-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-start;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Personal-Info-Height-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: black;
    height: 10px;
    /*border-radius: 10px;*/
  } 

.Personal-Info-Height-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.Personal-Info-Height-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#503b00;
    /*border-radius: 10px;*/
}

.Personal-Info-Height-Input-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
}

.Personal-Info-Title-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Personal-Info-Field-Wrapper-Parent{
    overflow-x: auto;
}

.Personal-Info-First-Name-Wrapper-Parent{
    overflow-x: auto;
}

.Personal-Info-Last-Name-Wrapper-Parent{
    overflow-x: auto;
}

.Personal-Info-DisplayName-Wrapper-Parent{
    overflow-x: auto;
}

.Personal-Info-Email-Wrapper-Parent{
    overflow-x: auto;
}

.Personal-Info-Weight-Wrapper-Parent{
    overflow-x: auto;
}

.Personal-Info-Height-Wrapper-Parent{
    overflow-x: auto;
}

.Personal-Info-Edit-Button-Wrapper{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Personal-Info-Edit-Button{
    width: 150px;
    height: 50px;
    border: none;
    background: #f1b100;
    font-size: 20px;
    box-shadow: 2px 2px 10px black;
}

.Personal-Info-Form-Title{
    font-size: 40px;
    text-align: center;
    margin-top: 0%;
}

/*.Personal-Info-First-Name-Label{
    margin-left: 20px;
    font-size: 25px;
}

.Personal-Info-Last-Name-Label{
    margin-left: 20px;
    font-size: 25px;
}

.Personal-Info-Email-Label{
    margin-left: 20px;
    font-size: 25px;
}

.Personal-Info-Weight-Label{
    margin-left: 20px;
    font-size: 25px;
}

.Personal-Info-Height-Label{
    margin-left: 20px;
    font-size: 25px;
}*/

.Personal-Info-Field{
    margin: 0;
    margin-left: 5px;
    width: 50%;
    justify-self: flex-start;
    font-size: 20px;
    white-space: nowrap;
}

.Personal-Info-First-Name{
    margin: 0;
    margin-left: 5px;
    width: 50%;
    justify-self: flex-start;
    font-size: 20px;
    white-space: nowrap;
}

.Personal-Info-Last-Name{
    margin: 0;
    margin-left: 5px;
    width: 50%;
    justify-self: flex-start;
    font-size: 20px;
    white-space: nowrap;
}

.Personal-Info-DisplayName{
    margin: 0;
    margin-left: 5px;
    width: 50%;
    justify-self: flex-start;
    font-size: 20px;
    white-space: nowrap;
}

.Personal-Info-Email{
    margin: 0;
    margin-left: 5px;
    width: 50%;
    justify-self: flex-start;
    font-size: 20px;
    white-space: nowrap;
}

.Personal-Info-Weight{
    margin: 0;
    margin-left: 5px;
    width: 50%;
    justify-self: flex-start;
    font-size: 20px;
    white-space: nowrap;
}

.Personal-Info-Height{
    margin: 0;
    margin-left: 5px;
    width: 20%;
    justify-self: flex-start;
    font-size: 20px;
    white-space: nowrap;
}

@media(max-width: 770px){

    /*.Personal-Info-First-Name-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .Personal-Info-Last-Name-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .Personal-Info-Email-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .Personal-Info-Weight-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .Personal-Info-Height-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .Personal-Info-Height-Input-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }*/

    .Personal-Info-Form-Title{
        font-size: 30px;
    }
    
    /*
    .Personal-Info-First-Name-Label{
        font-size: 20px;
        margin-left: 0;
    }
    
    .Personal-Info-Last-Name-Label{
        font-size: 20px;
        margin-left: 0;
    }
    
    .Personal-Info-Email-Label{
        font-size: 20px;
        margin-left: 0;
    }
    
    .Personal-Info-Weight-Label{
        font-size: 20px;
        margin-left: 0;
    }
    
    .Personal-Info-Height-Label{
        font-size: 20px;
        margin-left: 0;
    }
    
    .Personal-Info-First-Name{
        font-size: 15px;
        text-align: center;
        margin-left: 0;
    }
    
    .Personal-Info-Last-Name{
        font-size: 15px;
        text-align: center;
        margin-left: 0;
    }
    
    .Personal-Info-Email{
        font-size: 15px;
        text-align: center;
        margin-left: 0;
    }
    
    .Personal-Info-Weight{
        font-size: 15px;
        text-align: center;
        margin-left: 0;
    }
    
    .Personal-Info-Height{
        font-size: 15px;
        text-align: center;
        margin-left: 0;
    }*/
}

@media(max-width: 285px){
    .Personal-Info-Form-Title{
        font-size: 25px;
    }
}

@media(max-height: 750px){
    /*.Personal-Info-Form-Wrapper{
        height: 80%;
    }*/
}



