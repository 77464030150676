.Personal-Info-Edit-Container{
}

.Personal-Info-Edit-Wrapper{
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Personal-Info-Edit-Form-Wrapper{
    width: 70%;
    height: 60%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    background: #503b00;
    box-shadow: 2px 2px 10px black;
    padding: 5px;
}

.Personal-Info-Edit-Max-Wrapper{
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-end;
    width: 100%;
    margin-bottom: 5px;
}

.Personal-Info-Edit-First-Name-Wrapper{
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-end;
    width: 100%;
    margin-bottom: 5px;
}

.Personal-Info-Edit-Last-Name-Wrapper{
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-end;
    width: 100%;
    margin-bottom: 5px;
}

.Personal-Info-Edit-DisplayName-Wrapper{
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-end;
    width: 100%;
    margin-bottom: 5px;
}

.Personal-Info-Edit-Email-Wrapper{
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-end;
    width: 100%;
    margin-bottom: 5px;
}

.Personal-Info-Edit-Weight-Wrapper{
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-end;
    width: 100%;
    margin-bottom: 5px;
}

.Personal-Info-Edit-Height-Wrapper{
    display: grid;
    grid-template-columns: 1fr 2fr;
    justify-content: flex-start;
    align-items: center;
    justify-items: flex-end;
    width: 100%;
    margin-bottom: 5px;
}

.Personal-Info-Edit-Height-Input-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}

.Personal-Info-Edit-Title-Wrapper{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.Personal-Info-Edit-Submit-Wrapper{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.Personal-Info-Edit-Cancel-Button{
    margin-right: 40px;
    width: 150px;
    height: 50px;
    border: none;
    background: #808080;
    font-size: 20px;
    box-shadow: 2px 2px 10px black;
}

.Personal-Info-Edit-Save-Button{
    width: 150px;
    height: 50px;
    border: none;
    background: #f1b100;
    font-size: 20px;
    box-shadow: 2px 2px 10px black;
}

.Personal-Info-Edit-Form-Title{
    font-size: 40px;
    text-align: center;
}

.Personal-Info-Edit-Max-Label{
    margin-left: 20px;
}

.Personal-Info-Edit-First-Name-Label{
    margin-left: 20px;
}

.Personal-Info-Edit-Last-Name-Label{
    margin-left: 20px;
}

.Personal-Info-Edit-DisplayName-Label{
    margin-left: 20px;
}

.Personal-Info-Edit-Email-Label{
    margin-left: 20px;
}

.Personal-Info-Edit-Weight-Label{
    margin-left: 20px;
}

.Personal-Info-Edit-Height-Label{
    margin-left: 20px;
}

.Personal-Info-Edit-Max-Input{
    margin-left: 20px;
    width: 50%;
    justify-self: flex-start;
    height: 30px;
    border: none;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
}

.Personal-Info-Edit-First-Name-Input{
    margin-left: 20px;
    width: 50%;
    justify-self: flex-start;
    height: 30px;
    border: none;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
}

.Personal-Info-Edit-Last-Name-Input{
    margin-left: 20px;
    width: 50%;
    justify-self: flex-start;
    height: 30px;
    border: none;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
}

.Personal-Info-Edit-DisplayName-Input{
    margin-left: 20px;
    width: 50%;
    justify-self: flex-start;
    height: 30px;
    border: none;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
    
}

.Personal-Info-Edit-Email-Input{
    margin: 0;
    margin-left: 20px;
    width: 50%;
    justify-self: flex-start;
    height: 30px;
    border: none;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
    font-size: 15px;
    color: rgb(63, 63, 63);
}

.Personal-Info-Edit-Weight-Input{
    margin-left: 20px;
    width: 50%;
    justify-self: flex-start;
    height: 30px;
    border: none;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
}

.Personal-Info-Edit-Height-Feet-Input{
    margin-left: 20px;
    width: 20%;
    justify-self: flex-start;
    height: 30px;
    border: none;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
}

.Personal-Info-Edit-Height-Inches-Input{
    margin-left: 20px;
    width: 20%;
    justify-self: flex-start;
    height: 30px;
    border: none;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
}

@media(max-width: 770px){

    .Personal-Info-Edit-First-Name-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .Personal-Info-Edit-Last-Name-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .Personal-Info-Edit-DisplayName-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .Personal-Info-Edit-Email-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .Personal-Info-Edit-Weight-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .Personal-Info-Edit-Height-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
    .Personal-Info-Edit-Height-Input-Wrapper{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .Personal-Info-Edit-Form-Title{
        font-size: 30px;
    }
    
    .Personal-Info-Edit-First-Name-Label{
        font-size: 20px;
        margin-left: 0;
    }
    
    .Personal-Info-Edit-Last-Name-Label{
        font-size: 20px;
        margin-left: 0;
    }

    .Personal-Info-Edit-DisplayName-Label{
        font-size: 20px;
        margin-left: 0;
    }
    
    .Personal-Info-Edit-Email-Label{
        font-size: 20px;
        margin-left: 0;
    }
    
    .Personal-Info-Edit-Weight-Label{
        font-size: 20px;
        margin-left: 0;
    }
    
    .Personal-Info-Edit-Height-Label{
        font-size: 20px;
        margin-left: 0;
    }
    
    .Personal-Info-Edit-First-Name-Input{
        font-size: 15px;
        text-align: center;
        margin-left: 0;
        width: 70%;
    }
    
    .Personal-Info-Edit-Last-Name-Input{
        font-size: 15px;
        text-align: center;
        margin-left: 0;
        width: 70%;
    }

    .Personal-Info-Edit-DisplayName-Input{
        font-size: 15px;
        text-align: center;
        margin-left: 0;
        width: 70%;
    }
    
    .Personal-Info-Edit-Email-Input{
        font-size: 15px;
        text-align: center;
        margin-left: 0;
        width: 70%;
    }
    
    .Personal-Info-Edit-Weight-Input{
        font-size: 15px;
        text-align: center;
        margin-left: 0;
        width: 70%;
    }
    
    .Personal-Info-Edit-Height-Feet-Input{
        font-size: 15px;
        text-align: center;
        margin-left: 0;
        width: 30%;
    }

    .Personal-Info-Edit-Height-Inches-Input{
        font-size: 15px;
        text-align: center;
        margin-left: 10px;
        width: 30%;
    }
}

@media(max-height: 750px){
    /*.Personal-Info-Edit-Form-Wrapper{
        height: 80%;
    }*/
}

@media(max-height: 530px){
    /*.Personal-Info-Edit-Wrapper{
        margin-top: 100px;
    }

    .Personal-Info-Edit-Form-Wrapper{
        height: auto;
    }

    .Personal-Info-Edit-Submit-Wrapper{
        margin-top: 10px;
    }*/
}



