.ScheduleWeek-Row-Content-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 50%;
    background: #ffbb00;
    transition-duration: 0.5s;
    /*border: 1px black;
    border-style: solid;*/
}

.ScheduleWeek-Type{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.ScheduleWeek-THR{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 10%;
}

.ScheduleWeek-Max{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 5%;
}

.ScheduleWeek-Sets{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 5%;
}

.ScheduleWeek-Reps{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 5%;
}

.ScheduleWeek-Weight{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 7%;
}

.ScheduleWeek-TimeOn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15%;
}

.ScheduleWeek-TimeOff{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15%;
}

.ScheduleWeek-Frequency{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 18%;
}

.ScheduleWeek-Type-Content{
    font-size: 12px;
    text-align: center;
    width: 100px;
    /*white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
}

.ScheduleWeek-THR-Content{
    font-size: 12px;
    text-align: center;
    width: 60px;
    /*white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
}

.ScheduleWeek-Max-Content{
    font-size: 12px;
    text-align: center;
    width: 60px;
    /*white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
}

.ScheduleWeek-Sets-Content{
    font-size: 12px;
    text-align: center;
    width: 60px;
    /*white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
}

.ScheduleWeek-Reps-Content{
    font-size: 12px;
    text-align: center;
    width: 60px;
    /*white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
}

.ScheduleWeek-Weight-Content{
    font-size: 12px;
    text-align: center;
    width: 60px;
    /*white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
}

.ScheduleWeek-TimeOn-Content{
    font-size: 12px;
    text-align: center;
    width: 65px;
    /*white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
}

.ScheduleWeek-TimeOff-Content{
    font-size: 12px;
    text-align: center;
    width: 65px;
    /*white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
}

.ScheduleWeek-Frequency-Content{
    font-size: 12px;
    text-align: center;
    width: 100%;
    /*white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;*/
    margin: 0;
    z-index: 2;
    color: black;
}

@media(max-width: 767px){
    .ScheduleWeek-Type-Content{
        font-size: 10px;
        text-align: center;
        width: 60px;
    }
    
    .ScheduleWeek-THR-Content{
        font-size: 10px;
        text-align: center;
        width: 40px;
    }
    
    .ScheduleWeek-Max-Content{
        font-size: 10px;
        text-align: center;
        width: 40px;
    }
    
    .ScheduleWeek-Sets-Content{
        font-size: 10px;
        text-align: center;
        width: 40px;
    }
    
    .ScheduleWeek-Reps-Content{
        font-size: 10px;
        text-align: center;
        width: 40px;
    }
    
    .ScheduleWeek-Weight-Content{
        font-size: 10px;
        text-align: center;
        width: 40px;
    }
    
    .ScheduleWeek-TimeOn-Content{
        font-size: 10px;
        text-align: center;
        width: 50px;
    }
    
    .ScheduleWeek-TimeOff-Content{
        font-size: 10px;
        text-align: center;
        width: 50px;
    }
    
    .ScheduleWeek-Frequency-Content{
        font-size: 10px;
        text-align: center;
        width: 70px;
    }
}

@media(max-width: 600px){
    .ScheduleWeek-Type{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 20%;
    }
    
    .ScheduleWeek-THR{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 10%;
    }
    
    .ScheduleWeek-Max{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 12%;
    }
    
    .ScheduleWeek-Sets{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 10%;
    }
    
    .ScheduleWeek-Reps{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 10%;
    }
    
    .ScheduleWeek-Weight{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 10%;
    }
    
    .ScheduleWeek-TimeOn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15%;
    }
    
    .ScheduleWeek-TimeOff{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15%;
    }
    
    .ScheduleWeek-Frequency{
        display: none;
    }      

}

@media(max-width: 425px){
    .ScheduleWeek-Type{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 25%;
    }
    
    .ScheduleWeek-THR{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15%;
    }
    
    .ScheduleWeek-Max{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 17%;
    }
    
    .ScheduleWeek-Sets{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15%;
    }
    
    .ScheduleWeek-Reps{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15%;
    }
    
    .ScheduleWeek-Weight{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15%;
    }
    
    .ScheduleWeek-TimeOn{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15%;
        display: none;
    }
    
    .ScheduleWeek-TimeOff{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15%;
        display: none;
    }
    
    .ScheduleWeek-Frequency{
        display: none;
    }      

}