.Workout-Detail-Container{
}

.Workout-Detail-Wrapper{
    position: relative;
    top: 150px;
    bottom: 0;
    left: 0;
    right: 0;
    /*width: 100%;
    height: 100%;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.Workout-Detail-Form-Wrapper{
    width: 60%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    background: #503b00;
    box-shadow: 2px 2px 10px black;
}

.Workout-Detail-Classroom-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 11.1%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    transition-duration: 0.5s;
    background: #cc9600;
    height: 100%;
}

#Workout-Detail-Classroom-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #cc9600;
    height: 10px;
    /*border-radius: 10px;*/
  } 

#Workout-Detail-Classroom-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
#Workout-Detail-Classroom-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#cc9600;
    /*border-radius: 10px;*/
}

.Workout-Detail-Exercise-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 11.1%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    transition-duration: 0.5s;
    background: #ffbb00;
    height: 100%;
}

#Workout-Detail-Exercise-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #ffbb00;
    height: 10px;
    /*border-radius: 10px;*/
  } 

#Workout-Detail-Exercise-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
#Workout-Detail-Exercise-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffbb00;
    /*border-radius: 10px;*/
}

.Workout-Detail-THR-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 11.1%;
    overflow-x: scroll;
    overflow-y: hidden;
    background:  #cc9600;
    height: 100%;
}

#Workout-Detail-THR-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #cc9600;
    height: 10px;
    /*border-radius: 10px;*/
  } 

#Workout-Detail-THR-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
#Workout-Detail-THR-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #cc9600;
    /*border-radius: 10px;*/
}

.Workout-Detail-Classroom-Wrapper-Parent{
    height: 11.1%;
    overflow-x: auto;
}

.Workout-Detail-Exercise-Wrapper-Parent{
    height: 11.1%;
    overflow-x: auto;
}

.Workout-Detail-THR-Wrapper-Parent{
    height: 11.1%;
    overflow-x: auto;
}

.Workout-Detail-Max-Wrapper-Parent{
    height: 11.1%;
    overflow-x: auto;
}

.Workout-Detail-Sets-Wrapper-Parent{
    height: 11.1%;
    overflow-x: auto;
}

.Workout-Detail-Reps-Wrapper-Parent{
    height: 11.1%;
    overflow-x: auto;
}

.Workout-Detail-Weight-Wrapper-Parent{
    height: 11.1%;
    overflow-x: auto;
}

.Workout-Detail-TimeOn-Wrapper-Parent{
    height: 11.1%;
    overflow-x: auto;
}

.Workout-Detail-TimeOff-Wrapper-Parent{
    height: 11.1%;
    overflow-x: auto;
}

.Workout-Detail-Component-Wrapper-Parent{
    height: 11.1%;
    overflow-x: auto;
}

.Workout-Detail-Max-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #ffbb00;
    height: 100%;
}

#Workout-Detail-Max-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #ffbb00;
    height: 10px;
    /*border-radius: 10px;*/
  } 

#Workout-Detail-Max-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
#Workout-Detail-Max-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #ffbb00;
    /*border-radius: 10px;*/
}

.Workout-Detail-Sets-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 11.1%;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #cc9600;
    height: 100%;
}

#Workout-Detail-Sets-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #cc9600;
    height: 10px;
    /*border-radius: 10px;*/
  } 

#Workout-Detail-Sets-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
#Workout-Detail-Sets-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#cc9600;
    /*border-radius: 10px;*/
}

.Workout-Detail-Reps-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 11.1%;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #ffbb00;
    height: 100%;
}

#Workout-Detail-Reps-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #ffbb00;
    height: 10px;
    /*border-radius: 10px;*/
  } 

#Workout-Detail-Reps-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
#Workout-Detail-Reps-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffbb00;
    /*border-radius: 10px;*/
}

.Workout-Detail-Weight-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 11.1%;
    overflow-x: scroll;
    overflow-y: hidden;
    background:  #cc9600;
    height: 100%;
}

#Workout-Detail-Weight-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #cc9600;
    height: 10px;
    /*border-radius: 10px;*/
  } 

#Workout-Detail-Weight-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
#Workout-Detail-Weight-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#cc9600;
    /*border-radius: 10px;*/
}

.Workout-Detail-TimeOn-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 11.1%;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #ffbb00;
    height: 100%;
}

#Workout-Detail-TimeOn-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #ffbb00;
    height: 10px;
    /*border-radius: 10px;*/
  } 

#Workout-Detail-TimeOn-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
#Workout-Detail-TimeOn-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffbb00;
    /*border-radius: 10px;*/
}

.Workout-Detail-TimeOff-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 11.1%;
    overflow-x: scroll;
    overflow-y: hidden;
    background:  #cc9600;
    height: 100%;
}

#Workout-Detail-TimeOff-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #cc9600;
    height: 10px;
    /*border-radius: 10px;*/
  } 

#Workout-Detail-TimeOff-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
#Workout-Detail-TimeOff-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#cc9600;
    /*border-radius: 10px;*/
}

.Workout-Detail-Component-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 11.1%;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #ffbb00;
    height: 100%;
}

#Workout-Detail-Component-Wrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #ffbb00;
    height: 10px;
    /*border-radius: 10px;*/
  } 

#Workout-Detail-Component-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
#Workout-Detail-Component-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#ffbb00;
    /*border-radius: 10px;*/
}

.Workout-Detail-Classroom{
    font-size: 30px;
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.Workout-Detail-Exercise{
    font-size: 30px;
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.Workout-Detail-THR{
    font-size: 30px;
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.Workout-Detail-Max{
    font-size: 30px;
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.Workout-Detail-Sets{
    font-size: 30px;
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.Workout-Detail-Reps{
    font-size: 30px;
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.Workout-Detail-Weight{
    font-size: 30px;
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.Workout-Detail-TimeOn{
    font-size: 30px;
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.Workout-Detail-TimeOff{
    font-size: 30px;
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.Workout-Detail-Component{
    font-size: 30px;
    white-space: nowrap;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
}

.Workout-Detail-Button-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Workout-Detail-EditWorkout{
    width: 200px;
    height: 70px;
    font-size: 20px;
    border: none;
    box-shadow: 2px 2px 10px black;
    background: #ad7f00;
    transition-duration: .5s;
    margin-top: 20px;
    margin-right: 20px;
}

.Workout-Detail-EditWorkout:hover{
    background: #ffbb00;
}

.Workout-Detail-DeleteWorkout{
    width: 200px;
    height: 70px;
    font-size: 20px;
    border: none;
    box-shadow: 2px 2px 10px black;
    background: #ad7f00;
    transition-duration: .5s;
    margin-top: 20px;
}

.Workout-Detail-DeleteWorkout:hover{
    background: #ffbb00;
}

@media(max-width: 770px){

    .Workout-Detail-Classroom{
        font-size: 20px;
        white-space: nowrap;
    }
    
    .Workout-Detail-Exercise{
        font-size: 20px;
        white-space: nowrap;
    }
    
    .Workout-Detail-THR{
        font-size: 20px;
        white-space: nowrap;
    }
    
    .Workout-Detail-Max{
        font-size: 20px;
        white-space: nowrap;
    }
    
    .Workout-Detail-Sets{
        font-size: 20px;
        white-space: nowrap;
    }
    
    .Workout-Detail-Reps{
        font-size: 20px;
        white-space: nowrap;
    }
    
    .Workout-Detail-Weight{
        font-size: 20px;
        white-space: nowrap;
    }
    
    .Workout-Detail-TimeOn{
        font-size: 20px;
        white-space: nowrap;
    }
    
    .Workout-Detail-TimeOff{
        font-size: 20px;
        white-space: nowrap;
    }
    
    .Workout-Detail-Component{
        font-size: 20px;
        white-space: nowrap;
    }

}

@media(max-width: 730px){
    .Workout-Detail-Button-Wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Workout-Detail-EditWorkout{
        margin-right: 0px;
    }
}

@media(max-width: 425px){

    .Workout-Detail-Classroom{
        font-size: 15px;
        white-space: nowrap;
    }
    
    .Workout-Detail-Exercise{
        font-size: 15px;
        white-space: nowrap;
    }
    
    .Workout-Detail-THR{
        font-size: 15px;
        white-space: nowrap;
    }
    
    .Workout-Detail-Max{
        font-size: 15px;
        white-space: nowrap;
    }
    
    .Workout-Detail-Sets{
        font-size: 15px;
        white-space: nowrap;
    }
    
    .Workout-Detail-Reps{
        font-size: 15px;
        white-space: nowrap;
    }
    
    .Workout-Detail-Weight{
        font-size: 15px;
        white-space: nowrap;
    }
    
    .Workout-Detail-TimeOn{
        font-size: 15px;
        white-space: nowrap;
    }
    
    .Workout-Detail-TimeOff{
        font-size: 15px;
        white-space: nowrap;
    }
    
    .Workout-Detail-Component{
        font-size: 15px;
        white-space: nowrap;
    }

}

/*.Goal-Detail-Title-Label{
    margin-right: 20px;
}

.Goal-Detail-Title-Input{

}

.Goal-Detail-Progress-Label{
    margin-right: 20px;
}

.Goal-Detail-Progress-Input{
    
}

.Goal-Detail-Description-Label{
    margin-right: 20px;
}

.Goal-Detail-Description-Input{
    
}*/