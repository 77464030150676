.Scheduler-Admin-Container{
    height: 100%;
    width: 100%;
}

.Scheduler-Admin-Wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 100px;
}

.Scheduler-Admin-Date-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.Scheduler-Admin-Switch-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.Scheduler-Admin-Switch-InnerWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Scheduler-Admin-List-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.Scheduler-Admin-AllList-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.Scheduler-Admin-Save-Button-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.Scheduler-Admin-Start-Date-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
}

.Scheduler-Admin-End-Date-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Scheduler-Admin-Start-Date-Label{
    font-size: 20px;
    margin-bottom: 10px;
}

.Scheduler-Admin-End-Date-Label{
    font-size: 20px;
    margin-bottom: 10px;
}

.Scheduler-Admin-Start-Date{
    width: 190px;
    height: 30px;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
}

.Scheduler-Admin-End-Date{
    width: 190px;
    height: 30px;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
}

.Scheduler-Admin-H2-E{
    margin-right: 10px;
}

.Scheduler-Admin-H2-C{
    margin-left: 10px;
}

.switch{
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input{
    opacity: 0;
    width: 0;
    height: 0;
}

.slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ac8e3b;
    -webkit-transition: 0.4s;
    transition: .4s;
}

.slider::before{
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .Scheduler-Admin-List{
      width: 600px;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      background: #5e4500;
      margin-bottom: 10px;
      overflow-y: scroll;
      overflow-x: hidden;
  }

  .Scheduler-Admin-List::-webkit-scrollbar {
    width: 10px;
    background-color: #5e4500;
    visibility: hidden;
  } 

  .Scheduler-Admin-List::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.Scheduler-Admin-List::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #5e4500;
}

.Scheduler-Admin-AllList{
    width: 600px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #5e4500;
    margin-bottom: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Scheduler-Admin-AllList::-webkit-scrollbar {
    width: 10px;
    background-color: #5e4500;
    visibility: hidden;
  } 

  .Scheduler-Admin-AllList::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.Scheduler-Admin-AllList::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #5e4500;
}

.Scheduler-Admin-Save-Button{
    width: 120px;
    height: 40px;
    background: #ac8e3b;
    font-size: 20px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
}

.Scheduler-Admin-Save-Button:hover{
    background: #f1c752;
}

.Scheduler-Admin-AllList-Item{
    display: flex;
    flex-direction: row;
    width: 100%;
    transition-duration: 0.5s;
}

.Even{
    background: #997000;
}

.Odd{
    background: #c08d00;
}

.Selected{
    background: #ffffff;
}

.Scheduler-Admin-AllList-Item:hover{
    background: #ffffff;
}

.Scheduler-Admin-AllList-ItemText{
    margin-left: 10px;
}

.Scheduler-Admin-List-Item{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.Scheduler-Admin-List-Item:hover{
    background: #ffffff;
}

.Scheduler-Admin-List-ItemText{
    margin-left: 10px;
}

.Category-Admin-ListItemExerciseText{
    cursor: pointer;
    width: 160px;
    height: 50px;
    font-size: 20px;
    text-align: center;
    background: #7c5b00;
    border: none;
    style: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    outline: none;
    color: rgb(255, 255, 255);
}

.Scheduler-Admin-List-ItemRemove{
    font-size: 20px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
    width: 40px;
    height: 40px;
    background: #8b6a05;
    margin-right: 20px;
}

.Scheduler-Admin-List-ItemRemove:hover{
    background: #e4aa0a;
}

.Scheduler-Admin-List-TitleWrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 75%;
}

.Scheduler-Admin-List-ButtonWrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 25%;
}