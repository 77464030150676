.headerContainer{
    width: 100%;
    height: 100%;
}

.headerWrapper{
    position: fixed;
    top: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /*flex-direction: row;*/
    justify-content: center;
    align-items: center;
    /*background: linear-gradient(#6b4e00, #332500);*/
    background: rgba(0, 0, 0, 1);
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
    z-index: 3;
    box-shadow: 2px 2px 10px black;
}

.MMS-Title-Container{
    padding-right: 5%;
    height: 100%;
}

.hamburgerContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding-left: 5%;
}

.Page-Title-Container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.arrow{
    border: solid white;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 3px;
    height: 15px;
    width: 15px;
    transition-duration: .5s;
}

.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }  

.backArrowBackground{
    margin: 0;
    position: absolute;
    top: 15px;
    margin-top: 2px;
    z-index: 4;
}

.arrow:hover{
    border: solid #6b4e00;
    border-width: 0 5px 5px 0;
}

.pageTitle{
    position: absolute;
    color: white;
    margin: 0;
    bottom: 10%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lhsLogo{
    transition-duration: 1s;
    object-fit: contain;
}

.titleLink{
    text-decoration: none;
}

@media(max-width: 600px){
    .pageTitle{
        font-size: 25px;
    }

    .lhsLogo{
        height: 50px;
    }
}

@media(max-width: 425px){
    .MMS-Title-Container{
        padding: 5%;
    }

    .Page-Title-Container{
        align-items: flex-end;
    }

    .hamburgerContainer{
        align-items: flex-start;
        padding-top: 10%;
    }

    .lhsLogo{
        height: 40px;
    }
}