.authenticateContainer{
    width: 100vw;
    height: 100vh;
}

.authenticateWrapper{
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}

.authenticateWrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
    border-radius: 10px;
  } 

  .authenticateWrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
.authenticateWrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.authenticateForm{
    height: 400px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: black;
    border-radius: 20px;
    box-shadow: 2px 2px 10px black;
}

.Authneticate-Username-Row{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
}

.Authneticate-Password-Row{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
}

.Authneticate-Login-Row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.Authneticate-Create-Row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.Authneticate-Title-Row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}

.Authneticate-Fit4Life-Row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.topTitle{
    color: white;
}

.authenticateTitle{
    color: white;
    font-size: 30px;
}

.authenticateLabel{
    color: white;
    margin-right: 10px;
}

.authenticateLogin{
    width: 200px;
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
    background: radial-gradient(#ffffff, #666666);
}

.authenticateCreate{
    width: 200px;
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
    background: radial-gradient(#ffffff, #666666);
}

.authenticateInput{
    height: 20px;
    width: 200px;
    background: radial-gradient(#ffffff, #919191);
    border-radius: 5px;
    font-size: 15px;
    padding: 5px;
}

@media(max-width: 500px){
    .authenticateForm{
        height: 450px;
        width: 300px;
    }

    .Authneticate-Username-Row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    
    .Authneticate-Password-Row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }

}

@media(max-height: 450px){
    .authenticateForm{
        margin-top: 100px;
    }
}

@media(max-width: 350px){
    .authenticateForm{
        height: 450px;
        width: 250px;
    }

}