.Component-Edit-Container-Admin{
}

.Component-Edit-Wrapper-Admin{
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Component-Edit-Form-Wrapper-Admin{
    width: 50%;
    height: 30%;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background: #503b00;
    box-shadow: 2px 2px 10px black;
}

.Component-Edit-Title-Wrapper-Admin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70%;
}

.Component-Edit-Title-Label-Admin{
    font-size: 40px;
    text-align: center;
}

.Component-Edit-Title-Input-Admin{
    height: 30px;
    width: 350px;
    border: none;
    background: #ac8e3b;
    box-shadow: 2px 2px 10px black;
}

.Component-Edit-Button-Area-Admin{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Component-Edit-Save-Button-Admin{
    width: 100px;
    height: 30px;
    background: #ac8e3b;
    font-size: 20px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
    margin-right: 20px;
}

.Component-Edit-Save-Button-Admin:hover{
    background:#f5ca55;
}

.Component-Edit-Cancel-Button-Admin{
    width: 100px;
    height: 30px;
    background:#524626;
    font-size: 20px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
}

.Component-Edit-Cancel-Button-Admin:hover{
    background: #f5ca55;
}


@media(max-width: 860px){
    
    .Component-Edit-Form-Wrapper-Admin{
        width: 80%;
    }

    .Component-Edit-Title-Wrapper-Admin{
        justify-content: center;
        padding: 5px;
    }

    .Component-Edit-Title-Admin{
        margin-left: 0px;
    }

    .Component-Edit-Title-Label-Admin{
        font-size: 40px;
    }
     
    .Component-Edit-Title-Input-Admin{
        width: 80%;
    }
}

@media(max-width: 530px){

    .Component-Edit-Form-Wrapper-Admin{
        width: 90%;
    }

    .Component-Edit-Title-Label-Admin{
        font-size: 35px;
    }

}

@media(max-height: 750px){
    .Component-Edit-Title-Label-Admin{
        font-size: 30px;
    }

}
