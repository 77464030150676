.MMS-Title-Wrapper-Admin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    cursor: context-menu;
}

.MMS-Title-Admin{
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: 5px;
    text-align: center;
    margin: 0;
}

.MMS-Title-Admin-Symbol{
    text-align: center;
    margin: 0;
    color: #6b4e00;
    margin-right: 5px;
}

@media(max-width: 600px){
    .MMS-Title-Admin{
        font-size: 20px;
    }
}

@media(max-width: 425px){
    .MMS-Title-Wrapper-Admin{
        justify-content: flex-start;
    }

    .MMS-Title-Admin{
        font-size: 15px;
    }
}

