.exerciseContainer{
    background: #6b4e00;
    overflow-y: scroll;
    height: 100%;
} 

.exerciseWrapper{
    position: absolute;
    top: 200px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background: #6b4e00;
}

.exerciseForm{
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    width: 65%;
    background: black;
    border-radius: 20px;
    box-shadow: 2px 2px 10px black;
    padding: 10px;
}

.Exercise-Form-Title{
    color: white;
    font-size: 40px;
    text-align: center;
}

.Exercise-Title-Row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-bottom: 10px;
}

.Exercise-Form-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.Exercise-Details-Row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    margin-right: 0;
    width: 100%;
    margin-bottom: 5px;
}

/*TimeOn styling*/
.TimeOn-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.TimeOn-Minute-Wrapper{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TimeOn-Second-Wrapper{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TimeOn-Details-Minute-Label{
    color: white;
}

.TimeOn-Details-Second-Label{
    color: white;
}

.TimeOn-Details-Minute{
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(#ffffff, #aaaaaa);
    font-size: 20px;
    padding: 3px;
}

.TimeOn-Details-Second{
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(#ffffff, #aaaaaa);
    font-size: 20px;
    padding: 3px;
}

/*Rest styling*/
.Rest-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Rest-Minute-Wrapper{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Rest-Second-Wrapper{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Rest-Details-Minute-Label{
    color: white;
}

.Rest-Details-Second-Label{
    color: white;
}

.Rest-Details-Minute{
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(#ffffff, #aaaaaa);
    font-size: 20px;
    padding: 3px;
}

.Rest-Details-Second{
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(#ffffff, #aaaaaa);
    font-size: 20px;
    padding: 3px;
}

/*Sets styling*/
.Sets-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Sets-Details-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Sets-Details{
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(#ffffff, #aaaaaa);
    font-size: 20px;
    padding: 3px;
}


/*Reps styling*/
.Reps-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Reps-Details-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Reps-Details{
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(#ffffff, #aaaaaa);
    font-size: 20px;
    padding: 3px;
}

/*Max styling*/
.Max-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Max-Details-Wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Max-Details{
    width: 80px;
    height: 30px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(#ffffff, #aaaaaa);
    font-size: 20px;
    padding: 3px;
}

.exerciseLabel{
    text-align: center;
    font-size: 25px;
    color: white;
}

#Exercise-Details-Row-THR{
    height: 180px;
    overflow: hidden;
}

.exerciseInput{
    width: 190px;
    height: 30px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(#ffffff, #aaaaaa);
    font-size: 20px;
    padding: 3px;
}

#Exercise-Details-Row-Component{
    height: 180px;
    overflow: hidden;
}

.exerciseSelectWrapper{
    display: flex;
    flex-direction: column;
}

#exerciseSelectComponent{
    height: 65px;
    width: 320px;
}

#exerciseSelectComponent::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
    visibility: hidden;
    border-radius: 10px;
  } 

#exerciseSelectComponent::-webkit-scrollbar-thumb{
    background-color: rgb(46, 46, 46);
    border-radius: 10px;
}
  
#exerciseSelectComponent::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.exerciseCheckboxWrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    font-size: 17px;
}

.exerciseCheckbox{
    width: 17px;
    height: 17px;
}

.exerciseComponentLabel{
    color: white;
    margin-left: 5px;
}

.exerciseSelect{
    width: 195px;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    padding: 3px;
}

option{
    border-radius: 10px;
}

option:not(checked) { 
    background-color: #FFF; 
}

.exerciseSubmit{
    width: 200px;
    height: 40px;
    border-radius: 20px;
    border: none;
    font-size: 20px;
    background: radial-gradient(#ffffff, #666666);
    transition-duration: 1s;
}

.Exercise-Submit-Row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    margin-top: 20px;
}

@media(max-width: 1650px){
    .exerciseWrapper{
        top: 300px;
    }
}

@media(max-width: 800px){

    .Exercise-Form-Title{
        font-size: 38px;
    }

    .exerciseLabel{
        font-size: 18px;
    }
    
    .exerciseInput{
        width: 150px;
        height: 25px;
        font-size: 15px;
    }

    #Exercise-Details-Row-Component{
        height: 80px;
        overflow: hidden;
    }
    
    #exerciseSelectComponent{
        height: 50px;
        width: 320px;
    }

    .exerciseSelect{
        width: 195px;
        border-radius: 10px;
        border: none;
        font-size: 15px;
        padding: 3px;
    }

}

@media(max-width: 600px){

    .Exercise-Form-Title{
        font-size: 30px;
    }

    .exerciseForm{
        height: 1750px;
        width: 85%;
    }

    .exerciseWrapper{
        top: 200px;
    }

    .Exercise-Details-Row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 150px;
        width: 100%;
        margin-right: 0;
        margin-bottom: 5px;
    }

    .Exercise-Form-Wrapper{
        width: 100%;
    }

    .exerciseLabel{
        margin-right: 0;
        margin-bottom: 5px;
    }

    #Exercise-Details-Row-Component{
        height: 80px;
        overflow: hidden;
    }
    
    #exerciseSelectComponent{
        height: 50px;
        width: 225px;
    }

    .exerciseSelect{
        width: 195px;
        border-radius: 10px;
        border: none;
        font-size: 14px;
        padding: 3px;
    }

}

/*@media(max-width: 500px){
    .Exercise-Details-Row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .Exercise-Form-Wrapper{
        width: 100%;
    }

    .exerciseLabel{
        margin-right: 0;
        margin-bottom: 5px;
    }

    #Exercise-Details-Row-Component{
        height: 80px;
        overflow: hidden;
    }
    
    #exerciseSelectComponent{
        height: 50px;
        width: 180px;
    }

    .exerciseSelect{
        width: 150px;
        border-radius: 10px;
        border: none;
        font-size: 12px;
        padding: 3px;
    }

    #exerciseSelectComponent::-webkit-scrollbar {
        width: 0px;
        background-color: #F5F5F5;
        visibility: hidden;
        border-radius: 10px;
      } 
    
    #exerciseSelectComponent::-webkit-scrollbar-thumb{
        background-color: rgb(46, 46, 46);
        border-radius: 10px;
        visibility: hidden;
    }
      
    #exerciseSelectComponent::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
        border-radius: 10px;
        visibility: hidden;
    }

}

@media(max-width: 450px){

    .Exercise-Form-Title{
        font-size: 20px;
    }

    .exerciseLabel{
        font-size: 15px;
    }
    
    #Exercise-Details-Row-Component{
        height: 80px;
        overflow: hidden;
    }
    
    #exerciseSelectComponent{
        height: 50px;
        width: 170px;
    }

    .exerciseSelect{
        width: 140px;
        border-radius: 10px;
        border: none;
        font-size: 11px;
        padding: 3px;
    }

}

@media(max-width: 450px){

    .exerciseLabel{
        font-size: 15px;
    }
    
    .exerciseInput{
        width: 127px;
        height: 25px;
        font-size: 12px;
    }

    .exerciseSubmit{
        width: 175px;
        height: 35px;
        font-size: 18px;
    }

    .exerciseForm{
        width: 60%;
    }

}*/

@media(max-height: 1200px){
    .exerciseWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .exerciseForm{
        margin-top: 0;
    }
}

@media(max-height: 900px){
   /* .exerciseForm{
        position: absolute;
        top: 100px;
        margin-bottom: 50px;
    }*/
}