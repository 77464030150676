.BlowupDay-Row-Wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: #b98800;
}

.BlowupDay-Content-Wrapper{
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}

.BlowupDay-Content-Wrapper::-webkit-scrollbar {
    width: 10px;
    background-color: #b98800;
    height: 10px;
    /*border-radius: 10px;*/
  } 

  .BlowupDay-Content-Wrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}

.BlowupDay-Content-Wrapper::-webkit-scrollbar-corner{
    background-color: transparent;
}
  
#Workout-Detail-TimeOn-Wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color:#b98800;
    /*border-radius: 10px;*/
}

.BlowupDay-Row-Content-Wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.BlowupDay-Day{
    height: 100%;
    width: 15%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px black;
    border-style: solid;
    background: #b98800;
    transition-duration: .5s;
    /*border: 1px black;
    border-style: solid;*/
}

#BlowupDay-Day-Monday:hover{
    background: #dba100;
}

#BlowupDay-Day-Tuesday:hover{
    background: #dba100;
}

#BlowupDay-Day-Wednesday:hover{
    background: #dba100;
}

#BlowupDay-Day-Thursday:hover{
    background: #dba100;
}

#BlowupDay-Day-Friday:hover{
    background: #dba100;
}

.BlowupDay-Type{
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*border: 1px black;
    border-style: solid;*/
}

.BlowupDay-THR{
    height: 100%;
    width: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*border: 1px black;
    border-style: solid;*/
}

.BlowupDay-Max{
    height: 100%;
    width: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*border: 1px black;
    border-style: solid;*/
}

.BlowupDay-Sets{
    height: 100%;
    width: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*border: 1px black;
    border-style: solid;*/
}

.BlowupDay-Reps{
    height: 100%;
    width: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*border: 1px black;
    border-style: solid;*/
}

.BlowupDay-Weight{
    height: 100%;
    width: 5%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*border: 1px black;
    border-style: solid;*/
}

.BlowupDay-TimeOn{
    height: 100%;
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*border: 1px black;
    border-style: solid;*/
}

.BlowupDay-TimeOff{
    height: 100%;
    width: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*border: 1px black;
    border-style: solid;*/
}

.BlowupDay-Frequency{
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*border: 1px black;
    border-style: solid;*/
}

.BlowupDay-Day-Content{
    font-size: 13px;
}

/*.ScheduleWeek-Type-Content{
    font-size: 20px;
}

.ScheduleWeek-THR-Content{
    font-size: 20px;
}

.ScheduleWeek-Max-Content{
    font-size: 20px;
}

.ScheduleWeek-Sets-Content{
    font-size: 20px;
}

.ScheduleWeek-Reps-Content{
    font-size: 20px;
}

.ScheduleWeek-Weight-Content{
    font-size: 20px;
}

.ScheduleWeek-TimeOn-Content{
    font-size: 20px;
}

.ScheduleWeek-TimeOff-Content{
    font-size: 20px;
}

.ScheduleWeek-Frequency-Content{
    font-size: 20px;
}   */
