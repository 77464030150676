
.Exercise-TileCopy-Wrapper{
    width: 300px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #000000;
    border-radius: 20px;
    box-shadow: 2px 2px 10px black;
    margin: 20px;
    transition-duration: 1s;
}

.Exercise-TileCopy-Selected-Wrapper{
    width: 300px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #2e2e2e;
    border-radius: 20px;
    box-shadow: 2px 2px 10px black;
    margin: 20px;
    transition-duration: 1s;
}

.Exercise-TileCopy-Wrapper:hover{
    background: #2e2e2e;
}

.Exercise-TileCopy-Selected-Wrapper:hover{
    background: #2e2e2e;
}

.Exercise-TileCopy-Image-Wrapper{
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.Exercise-TileCopy-Image{
    object-fit: fill;
    border-radius: 20px;
    height: 80%;
    width: 90%;
    margin-top: 10px;
}

.Exercise-TileCopy-Title-Wrapper{
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    overflow: scroll;
}

.Exercise-TileCopy-Title-Wrapper::-webkit-scrollbar {
    display: none;
    width: 15px;
    background-color: #F5F5F5;
    border-radius: 10px;
  } 

  .Exercise-TileCopy-Title-Wrapper::-webkit-scrollbar-thumb{
    display: none;
    background-color: black;
    border-radius: 10px;
}
  
.Exercise-TileCopy-Title-Wrapper::-webkit-scrollbar-track {
    display: none;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.Exercise-TileCopy-Title{
    font-size: 20px;
    color: white;
    margin: 0;
    text-align: center;
}

@media(max-width: 700px){
    .Exercise-TileCopy-Wrapper{
        width: 200px;
        height: 250px;
    }

}

@media(max-width: 490px){
    .Exercise-TileCopy-Wrapper{
        width: 150px;
        height: 200px;
    }
}

@media(max-width: 375px){
    .Exercise-TileCopy-Wrapper{
        width: 100px;
        height: 150px;
    }
}