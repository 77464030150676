/*body{
    overflow-y: hidden;
}*/

.groupMemberContainer-Admin{
    width: 100%;
    height: 100%;
    background: #6b4e00;
    overflow-y: hidden;
}

.groupMemberWrapper-Admin{
    position: absolute;
    top: 100px;
    bottom: 120px;
    left: 0;
    right: 0;
    width: 100%;
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: hidden;
}

.groupMemberList-Admin::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
    visibility: hidden;
  } 

.groupMemberList-Admin::-webkit-scrollbar-thumb{
    background-color: black;
}
  
.groupMemberList-Admin::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.groupMemberList-Admin{
    width: 100%;
    height: 100%;
    background: #6b4e00;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}

.groupMemberFiller-Admin{
    width: 100%;
    min-height: 100px;
    background: black;
}

.GroupMember-List-Item-Admin{
    width:100%;
    min-height: 100px;
    background: #c08d00;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}

.GroupMember-List-Item-Admin{
    width:100%;
    min-height: 100px;
    background: #bb911f;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
}

.GroupMember-Grid-Cell-Admin{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
}

.GroupMember-List-Item-Title-Admin{
    font-size: 30px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    transition-duration: .5s;
}

.GroupMember-List-Item-Title-Admin:hover{
    color: #ffffff;
    cursor: pointer;
}

.GroupMember-List-Item-Copy-Admin{
    font-size: 30px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.GroupMember-List-Item-Copy-Button-Admin{
    background: #ac7e00;
    font-size: 30px;
    transition-duration: .5s;
    border: none;
    box-shadow: 2px 2px 10px black;
    width: 50px;
    height: 50px;
    margin-right: 20%;
}

.GroupMember-List-Item-Copy-Button-Admin:hover{
    background: #ffbb00;
}

.Fit4Life-SearchbarWrapper-Admin{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Fit4Life-Searchbar-Admin{
    width: 200px;
    height: 30px;
    margin-bottom: 15px;
}

.Fit4Life-SearchButton-Admin{
    width: 200px;
    height: 30px;
    border: none;
    border-radius: 15px;
    background: #ac8e3b;
    transition-duration: 0.5s;
}

.Fit4Life-SearchButton-Admin:hover{
    background: #dfb84c;
}

@media(max-width: 1100px){

    .GroupMember-List-Item-Admin{
        width:100%;
        min-height: 100px;
        background: #c08d00;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
        'title copy'
        'title copy';
        justify-content: center;
        justify-items: center;
        align-content: center;
        align-items: center;
    }

    .GroupMember-Grid-Cell-Admin{
        width: 30vw;
    }

    .GroupMember-List-Item-Title-Admin{
        font-size: 20px;
    }

    .GroupMember-List-Item-Copy-Button-Admin{
        grid-area: copy;
        font-size: 20px;
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
        width: 40px;
        height: 40px;
    }

    .GroupMember-Grid-Cell-Title-Admin{
        grid-area: title;
        height: 50px;
    }

    .GroupMember-Grid-Cell-Copy-Admin{
        grid-area: copy;
        height: 50px;
    }

}

@media(max-width: 630px){
    .GroupMember-List-Item-Title-Admin{
        font-size: 13px;
    }

    .GroupMember-List-Item-Copy-Button-Admin{
        transition-duration: .5s;
        border: none;
        box-shadow: 2px 2px 10px black;
    }

}

@media(max-width: 319px){
    /*.Goal-List-Item{
        grid-template-columns: 1fr 1fr 1fr;
    }*/

  /*  .Goal-Grid-Cell-Progress{
        display: none;
    }*/

}