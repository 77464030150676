.changePasswordContainer{
    width: 100vw;
    height: 100vh;
    background: #6b4e00;
} 

.changePasswordWrapper{
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    background: #6b4e00;
}

.changePasswordForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
    width: 70%;
    margin-top: 200px;
    background: black;
    border-radius: 20px;
    box-shadow: 2px 2px 10px black;
    padding: 40px;
}

.ChangePassword-Form-Title{
    color: white;
    font-size: 40px;
    text-align: center;
}

.ChangePassword-Title-Row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-bottom: 50px;
}

.ChangePassword-Form-Wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.ChangePassword-Details-Row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    margin-right: 20px;
}

.changePasswordLabel{
    margin-right: 20px;
    font-size: 25px;
    text-align: center;
    color: white;
}

.changePasswordInput{
    width: 190px;
    height: 30px;
    border-radius: 10px;
    border: none;
    background: radial-gradient(#ffffff, #aaaaaa);
    font-size: 20px;
    padding: 3px;
}

.changePasswordChange{
    width: 200px;
    height: 40px;
    border-radius: 20px;
    border: none;
    font-size: 20px;
    background: radial-gradient(#ffffff, #666666);
    transition-duration: 1s;
}

.ChangePassword-Change-Row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
    margin-top: 20px;
}

@media(max-width: 800px){
    .changePasswordLabel{
        font-size: 18px;
    }
    
    .changePasswordInput{
        width: 150px;
        height: 25px;
        font-size: 15px;
    }

}

@media(max-width: 600px){
    .ChangePassword-Details-Row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .ChangePassword-Form-Wrapper{
        width: 100%;
    }

    .changePasswordLabel{
        margin-right: 0;
        margin-bottom: 5px;
    }

}

@media(max-width: 500px){
    .ChangePassword-Details-Row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
    }

    .ChangePassword-Form-Wrapper{
        width: 100%;
    }

    .changePasswordLabel{
        margin-right: 0;
        margin-bottom: 5px;
    }

}

@media(max-width: 450px){
    .ChangePassword-Form-Title{
        font-size: 25px;
    }

    .changePasswordLabel{
        font-size: 15px;
    }
    
    .changePasswordInput{
        width: 127px;
        height: 25px;
        font-size: 12px;
    }

    .changePasswordChange{
        width: 175px;
        height: 35px;
        font-size: 18px;
    }

    .changePasswordForm{
        width: 60%;
    }

}

@media(max-height: 1200px){
    .changePasswordWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .changePasswordForm{
        margin-top: 0;
    }
}

@media(max-height: 900px){
    .changePasswordForm{
        position: absolute;
        top: 100px;
        margin-bottom: 50px;
    }
}