.confirmCreateContainer{
    display: none;
    position: fixed;
    /*display: flex;*/
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Sit on top */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.5);
}

.confirmCreateContent{
    background-color: #9b7200;
    margin: auto;
    padding: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 10px black;
}

/*.modalClose{
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}*/

.confirmCreateClose:hover,
.confirmCreateClose:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.ConfirmCreate-Button-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.confirmCreateText{
    font-size: 20px;
}

.confirmCreateConfirmText{
    width: 120px;
    height: 50px;
    margin-right: 10px;
    border: none;
    background: #7c5b00;
    transition-duration: .5s;
}

.confirmCreateConfirmText:hover{
    background: #584100;
}