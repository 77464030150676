.Scroll-Picker-Container{
    width: 100%;
    height: 100%;
}

.Scroll-Picker-Wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: space-around;
    width: 100%;
    height: 100%;
}

.Picker-ColumnWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.Picker-ColumnTitle{
    color: white;
    font-size: 20px;
    margin: 0px;
}

.Picker-ColumnDiv{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100px;
    width: 80px;
    overflow-y: scroll;
    overflow-x: hidden;
    /*scroll-snap-type: y mandatory;*/
    transition-duration: .1s;
}

.Picker-ColumnScrollDiv{
    transition-duration: .1s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Picker-ColumnDiv::-webkit-scrollbar {
    width: 0px;
    background-color: #F5F5F5;
    visibility: hidden;
    border-radius: 10px;
  } 

.Picker-ColumnDiv::-webkit-scrollbar-thumb{
    width: 0px;
    background-color: rgb(46, 46, 46);
    border-radius: 10px;
    visibility: hidden;
}
  
.Picker-ColumnDiv::-webkit-scrollbar-track {
    width: 0px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
    visibility: hidden;
}

.Picker-ItemWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 80px;
    cursor: pointer;
    background: radial-gradient(#111111, #000000);
   /* scroll-snap-align: center; */
    transition-duration: 2s;
}

.Picker-Item{
    color: white;
    font-size: 50px;
    transition-duration: .1s;
}