.createAccountWrapper{
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
}

.createAccountWrapper::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
    border-radius: 10px;
  } 

  .createAccountWrapper::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 10px;
}
  
.createAccountWrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.createAccountForm{
    margin-top: 30px;
    height: 700px;
    width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: black;
    border-radius: 20px;
    box-shadow: 2px 2px 10px black;
    position: absolute;
    top: 10px;
}

.-Username-Row{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
}

.CreateAccount-Title-Row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.CreateAccount-FirstName-Row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.CreateAccount-LastName-Row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.CreateAccount-Email-Row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.CreateAccount-DisplayName-Row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.CreateAccount-Password-Row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    
}

.CreateAccount-ConfirmPassword-Row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.CreateAccount-CreateButton-Row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.CreateAccount-CancelButton-Row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.topTitle{
    color: white;
}

.createAccountTitle{
    color: white;
    font-size: 30px;
}

.createAccountLabel{
    color: white;
    margin-right: 10px;
}

.createAccountLogin{
    width: 200px;
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
    background: radial-gradient(#ffffff, #666666);
}

.createAccountCreate{
    width: 200px;
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
    background: radial-gradient(#ffffff, #666666);
}

.createAccountInput{
    height: 20px;
    width: 200px;
    background: radial-gradient(#ffffff, #919191);
    border-radius: 5px;
    font-size: 15px;
    padding: 5px;
}

.createAccountCancel{
    width: 200px;
    height: 40px;
    border-radius: 5px;
    font-size: 20px;
    background: radial-gradient(#ffffff, #666666);
}

@media(max-width: 650px){
    .createAccountForm{
        width: 300px;
    }

    .CreateAccount-FirstName-Row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .CreateAccount-LastName-Row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .CreateAccount-Email-Row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
    }
    
    .CreateAccount-Password-Row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;
        
    }
    
    .CreateAccount-ConfirmPassword-Row{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

}

@media(max-height: 800px){
    /*.createAccountWrapper{
        position: absolute;
        height: 700px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }*/
}

@media(max-width: 350px){

    .createAccountTitle{
        color: white;
        font-size: 25px;
    }

    .createAccountForm{
        width: 250px;
    }

}